import { LoadingButton } from '@mui/lab';
import {
  Button,
  Grid,
  GridProps,
  Link as MuiLink,
  Stack,
  StackProps,
  Tooltip,
  Typography,
} from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import { useQuery } from '@tanstack/react-query';
import { paths } from 'App';
import IconArrowRight from 'components/icons/IconArrowRight';
import IconChecked from 'components/icons/IconChecked';
import IconExtLink from 'components/icons/IconExtLink';
import IconInfo from 'components/icons/IconInfo';
import { LaunchPoolLabel } from 'components/VaultLabels';
import { useAlerts } from 'contexts/AlertsContext';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { StakingContractInfo, useStakingInfo } from 'contexts/StakingInfoContext';
import { useV2Vaults } from 'contexts/V2VaultsContext';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import REALT_VAULT_ABI from 'core/contracts/REALT_VAULT_ABI';
import { commify, formatWithPrecision } from 'core/utils';
import { useContractRead } from 'hooks/useContractRead';
import { useWriteTx } from 'hooks/useWriteTx';
import { FC } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Address, formatUnits } from 'viem';
import { useAccount } from 'wagmi';

import StakeERC20VaultButton from './StakeERC20VaultButton';

const v1VaultsConfigs = window.appConfig?.vaults || {};
const v2Vaults = window.appConfig?.v2Vaults;
const erc20Info = window.appConfig?.erc20Info;
const launchPoolTokens = window.appConfig?.launchPoolTokens;
const reAltAddr = window.appConfig?.erc4626Vault;

const altTokenInfo = {
  iconUrl: '/icons/iconAltLayer.svg',
  tokenSymbol: 'ALT',
};

export default function StakingPoolSelect() {
  const { vaultId: _vaultId } = useParams();
  const vaultId = parseInt(_vaultId || '');

  return (
    <Box>
      <VaultBanner vaultId={vaultId} />
      <StakingPools vaultId={vaultId} />
    </Box>
  );
}

const VaultBanner = ({ vaultId, ...rest }: StackProps & { vaultId: number }) => {
  const isLaunchPool = !v2Vaults?.[vaultId]?.isLive;

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="flex-start"
      p={4}
      sx={{
        borderBottom: '1px solid #20272310',
      }}
      {...rest}
    >
      <Stack direction="row" flexWrap="wrap" justifyContent="flex-start" width="100%">
        <Stack
          alignItems="flex-start"
          columnGap={2}
          direction="row"
          flex={1}
          justifyContent="flex-start"
          order={{ xs: 2, sm: 0 }}
          sx={{
            mt: { xs: 2, sm: 0 },
            flexBasis: { xs: '100%', sm: 'unset' },
          }}
        >
          <Box
            component="img"
            src={v2Vaults?.[vaultId]?.iconUrl}
            sx={{
              width: 38,
            }}
          />
          <Typography fontWeight={500} variant="h2">
            {v2Vaults?.[vaultId]?.name}
          </Typography>
        </Stack>
        <Typography
          component={Link}
          fontSize={14}
          fontWeight={400}
          rel="noopener noreferrer"
          sx={{
            position: 'relative',
            bottom: 2,
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
            mr: 4,
            order: { xs: 3, sm: 0 },
          }}
          target="_blank"
          to={v2Vaults?.[vaultId]?.twitter}
        >
          TWITTER
          <IconExtLink />
        </Typography>
        <Typography
          component={Link}
          fontSize={14}
          fontWeight={400}
          rel="noopener noreferrer"
          sx={{
            position: 'relative',
            bottom: 2,
            cursor: 'pointer',
            '&:hover': { textDecoration: 'underline' },
            mr: 4,
            order: { xs: 3, sm: 0 },
          }}
          target="_blank"
          to={v2Vaults?.[vaultId]?.website}
        >
          WEBSITE
          <IconExtLink />
        </Typography>
        <Typography
          sx={{
            order: { xs: 1, sm: 0 },
            flexBasis: { xs: '100%', sm: 'unset' },
          }}
        >
          {isLaunchPool && <LaunchPoolLabel />}
        </Typography>
      </Stack>
      <Stack
        sx={{
          '&&': { px: { lg: 4.5, xs: 0 } },
        }}
      >
        <Typography my={4} px={{ xs: 0, lg: 2.4 }} variant="h6">
          {v2Vaults?.[vaultId]?.desc}
        </Typography>
        {v2Vaults?.[vaultId]?.tokenSymbol === 'CYBER' && (
          <Typography
            sx={{
              bgcolor: '#F5F5DA',
              border: '1px solid #20272317',
              p: 2,
              fontSize: 12,
            }}
            variant="subtitle1"
          >
            <IconInfo sx={{ width: 20, height: 20, mb: -0.8, mr: 0.5 }} />
            ReALT and Cyber Pool: Cyber token rewards will be distributed on Cyber L2. If you are
            using a smart contract wallet, please ensure that your wallet is available and supported
            on Cyber L2
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

const StakingPools = ({ vaultId, ...rest }: StackProps & { vaultId: number }) => {
  const { stakingInfo } = useStakingInfo();

  return (
    <Grid container {...rest}>
      <ReALTPoolCard />
      <EigenlayerPoolCard />
      {v2Vaults?.[vaultId]?.website === 'https://xter.io' && (
        <V1PoolCard
          stakingContractInfo={
            stakingInfo?.xterio?.[v1VaultsConfigs?.xterio?.stakingContracts?.[0]]
          }
          vaultId="xterio"
        />
      )}
      {v2Vaults?.[vaultId]?.erc20VaultAddr && <ERC20PoolCard />}
      {v2Vaults?.[vaultId]?.tokenSymbol === 'CYBER' && <CyberPoolCard />}
    </Grid>
  );
};

const PoolCardContainer: FC<GridProps> = ({ children, sx, ...rest }) => {
  return (
    <Grid
      container
      direction="column"
      item
      lg={4}
      md={6}
      rowGap={2}
      sx={{
        background: 'linear-gradient(127.48deg, #EAF4DC 0%, #D5E9BB 100%)',
        p: 4,
        ...sx,
      }}
      xs={12}
      {...rest}
    >
      {children}
    </Grid>
  );
};

const ReALTPoolCard: FC<BoxProps> = () => {
  const { vaultId: _vaultId } = useParams();
  const vaultId = parseInt(_vaultId || '');
  const { address } = useAccount();
  const { prices, tokens } = useStakedTokens();

  const { data: poolTvl } = useQuery({
    queryKey: ['reAlt-tvl', vaultId],
    queryFn: async () => {
      const res = await fetch(`https://climbing-man-45016.upstash.io/get/reALT-${vaultId}`, {
        headers: { Authorization: 'Bearer Aq_YAAIgcDG9Vb0WMcmhu1MkqazwNwvWaFtn_faIOrtkIS1OOa2V7A' },
      });

      return await res.json();
    },
    select: res => {
      return BigInt(res?.result || 0n);
    },
  });

  const poolTvlUsd =
    (prices?.[reAltAddr] || 0) *
    Number(formatUnits(poolTvl || 0n, tokens?.[reAltAddr]?.decimals || 18));

  const { fetchOptInStatuses, optInVaultMap } = useV2Vaults();

  const optedIn = optInVaultMap[vaultId];

  const { isPending: isOptingIn, write: optIn } = useWriteTx({
    contractAbi: REALT_VAULT_ABI,
    contractAddress: reAltAddr,
    functionName: 'optIn',
    functionArgs: [BigInt(vaultId || 0)],
    txKey: `optIn_${reAltAddr}`,
    onTxConfirmed: () => {
      fetchOptInStatuses();
    },
  });

  const isCyberVault = v2Vaults?.[vaultId]?.tokenSymbol === 'CYBER';
  const _rewardTokens = [
    { tokenSymbol: v2Vaults?.[vaultId]?.tokenSymbol, iconUrl: v2Vaults?.[vaultId]?.iconUrl },
    altTokenInfo,
  ];
  const rewardTokens = _rewardTokens?.filter(cur =>
    isCyberVault ? true : cur?.tokenSymbol !== 'ALT'
  );

  return (
    <PoolCardContainer>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <Tooltip placement="top" title={'reALT'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box component="img" src={'/icons/iconReAlt.svg'} sx={{ width: 56 }} />
          </Box>
        </Tooltip>
        <IconArrowRight sx={{ mx: 1, height: 15 }} />
        {rewardTokens?.map((tkn, index) => (
          <Tooltip key={tkn?.tokenSymbol} placement="top" title={tkn.tokenSymbol}>
            <Box
              component="img"
              src={tkn?.iconUrl}
              sx={{ width: 56, ml: isCyberVault ? -index * 2 : 'unset', zIndex: 1 + index }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Stack>
        <Typography sx={{ '&&': { fontSize: 24 } }} variant="h2">
          Restaked ALT Pool
        </Typography>
        <Typography sx={{ '&&&': { pt: 0.5 } }}>
          RESTAKE reALT, EARN {rewardTokens?.map(cur => cur.tokenSymbol)?.join(' + ')}
        </Typography>
      </Stack>
      <Stack>
        <Tooltip placement="right" title={`$ ${commify(poolTvlUsd?.toFixed(0))}`}>
          <Typography component="span" variant="h2" width="max-content">
            {commify(formatWithPrecision(poolTvl || 0n))} reALT
          </Typography>
        </Tooltip>
        <Typography>POOL TVL</Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <LoadingButton
          disabled={!address || optedIn}
          fullWidth
          loading={isOptingIn}
          onClick={() => {
            optIn();
          }}
          variant="contained"
        >
          {optedIn ? (
            <>
              <Typography>Delegated</Typography>
              <IconChecked sx={{ ml: 0.5, color: '#333c' }} />
            </>
          ) : (
            'Delegate'
          )}
        </LoadingButton>
      </Stack>
    </PoolCardContainer>
  );
};

const EigenlayerPoolCard = () => {
  const { vaultId: _vaultId } = useParams();
  const vaultId = parseInt(_vaultId || '');
  const { prices } = useStakedTokens();
  const { avsAddr, guide } = v2Vaults?.[vaultId] || {};
  const stakedTokenSymbol = 'ETH';

  const isCyberVault = v2Vaults?.[vaultId]?.tokenSymbol === 'CYBER';
  const _rewardTokens = [
    { tokenSymbol: v2Vaults?.[vaultId]?.tokenSymbol, iconUrl: v2Vaults?.[vaultId]?.iconUrl },
    altTokenInfo,
  ];
  const rewardTokens = _rewardTokens?.filter(cur =>
    isCyberVault ? true : cur?.tokenSymbol !== 'ALT'
  );

  const { data: ethTvl } = useQuery({
    queryKey: ['FETCH_AVS_TVL', avsAddr],
    queryFn: async () => {
      const response = await fetch(`https://api.eigenexplorer.com/avs/${avsAddr}?withTvl=true`);
      const result = await response.json();
      const ethTvl = result?.tvl?.tvl;

      return ethTvl;
    },
    enabled: Boolean(avsAddr && prices?.['0x0']),
  });

  const usdTvl = prices?.['0x0'] * ethTvl || 0;

  return (
    <PoolCardContainer>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <Tooltip placement="top" title={'ETH'}>
          <Box component="img" src={'/icons/iconEthereum.svg'} sx={{ width: 56 }} />
        </Tooltip>
        <IconArrowRight sx={{ mx: 1, height: 15 }} />
        {rewardTokens?.map((tkn, index) => (
          <Tooltip key={tkn?.tokenSymbol} placement="top" title={tkn.tokenSymbol}>
            <Box
              component="img"
              src={tkn?.iconUrl}
              sx={{ width: 56, ml: isCyberVault ? -index * 2 : 'unset', zIndex: 1 + index }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Stack>
        <Typography variant="h2">{stakedTokenSymbol} Pool</Typography>
        <Typography sx={{ '&&&': { pt: 0.5 } }}>
          RESTAKE {stakedTokenSymbol}, EARN {rewardTokens?.map(cur => cur.tokenSymbol)?.join(' + ')}
        </Typography>
      </Stack>
      <Stack>
        <Tooltip placement="right" title={`$ ${commify(usdTvl?.toFixed(0))}`}>
          <Typography component="span" variant="h2" width="max-content">
            {commify(formatWithPrecision(ethTvl || 0n, undefined, 18))} ETH
          </Typography>
        </Tooltip>
        <Typography>POOL TVL</Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Button
          component={MuiLink}
          fullWidth
          href={`https://app.eigenlayer.xyz/avs/${avsAddr}`}
          rel="noopener noreferrer"
          target="_blank"
          variant="contained"
        >
          <Box
            component="img"
            src={'/icons/iconEigenLayer.png'}
            sx={{ width: 25, bgcolor: 'white', p: 0.4, borderRadius: '0.2rem', mr: 2 }}
          />{' '}
          Delegate <IconExtLink sx={{ top: 0 }} />
        </Button>
        <MuiLink
          className="hover-grow"
          component={Link}
          rel="noopener noreferrer"
          sx={{
            mt: 1.5,
            position: 'relative',
            top: -2,
            whiteSpace: 'nowrap',
          }}
          target="_blank"
          to={guide || ''}
        >
          <Typography
            component="span"
            sx={{ color: theme => theme.colors.schema.info, textDecoration: 'underline' }}
          >
            Learn more
            {/* <IconExtLink sx={{ position: 'relative', left: -8, top: 8 }} /> */}
          </Typography>
        </MuiLink>
      </Stack>
    </PoolCardContainer>
  );
};

const V1PoolCard = ({
  stakingContractInfo,
  vaultId,
}: BoxProps & {
  stakingContractInfo: StakingContractInfo;
  vaultId: string;
}) => {
  const navigate = useNavigate();
  const { prices, tokens } = useStakedTokens();
  const stakedTokenSymbol = tokens?.[stakingContractInfo?.stakedTokenAddr as Address]?.symbol;

  const poolTvl = stakingContractInfo?.operatorList?.reduce(
    (tvl, operator) => tvl + operator.totalVotingStake,
    0n
  );

  return (
    <PoolCardContainer>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <Tooltip
          placement="top"
          title={tokens?.[stakingContractInfo?.stakedTokenAddr as Address]?.symbol}
        >
          <Box
            component="img"
            src={erc20Info?.[stakingContractInfo?.stakedTokenAddr as Address]?.iconUrl}
            sx={{ width: 56 }}
          />
        </Tooltip>
        <IconArrowRight sx={{ mx: 1, height: 15 }} />
        {launchPoolTokens?.[vaultId || '']?.map((launchToken, index) => (
          <Tooltip key={launchToken.tokenName} placement="top" title={launchToken?.tokenSymbol}>
            <Box component="img" src={launchToken?.iconUrl} sx={{ width: 56, zIndex: 1 + index }} />
          </Tooltip>
        ))}
      </Stack>
      <Stack>
        <Typography variant="h2">{stakedTokenSymbol} Pool</Typography>
        <Typography sx={{ '&&&': { pt: 0.5 } }}>
          Stake {stakedTokenSymbol}, Earn{' '}
          {launchPoolTokens?.[vaultId || '']?.map(token => token.tokenSymbol)?.join('/')}
        </Typography>
      </Stack>
      <Stack>
        <Tooltip
          placement="right"
          title={`$ ${commify(
            (
              Number(formatWithPrecision(poolTvl || 0n)) *
                prices?.[stakingContractInfo?.stakedTokenAddr || '0x'] || 0
            )?.toFixed(0)
          )}`}
        >
          <Typography component="span" variant="h2" width="max-content">
            {commify(formatWithPrecision(poolTvl || 0n))} {stakingContractInfo?.stakedTokenSymbol}
          </Typography>
        </Tooltip>
        <Typography>POOL TVL</Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Button
          disabled
          fullWidth
          onClick={() =>
            navigate(paths.Stake, {
              state: { stakedTokenAddr: stakingContractInfo?.stakedTokenAddr },
            })
          }
          variant="contained"
        >
          Withdrawals Only
        </Button>
      </Stack>
    </PoolCardContainer>
  );
};

const ERC20PoolCard: FC<BoxProps> = () => {
  const { vaultId: _vaultId } = useParams();

  const vaultId = parseInt(_vaultId || '');
  const stakedTokenAddr = v2Vaults?.[vaultId]?.stakedTokenAddr || '0x';
  const erc20VaultAddr = v2Vaults?.[vaultId]?.erc20VaultAddr || '0x';

  const { prices, tokens } = useStakedTokens();

  const { showReadContractError } = useAlerts();

  const { data: poolTvl, refetch: refetchPoolTvl } = useContractRead<bigint>({
    address: erc20VaultAddr,
    abi: ERC20_VAULT_ABI,
    functionName: 'totalSupply',
    onError: (err: Error) => {
      showReadContractError('totalSupply', err);
    },
  });

  const poolTvlFormatted = formatWithPrecision(
    poolTvl || 0n,
    undefined,
    tokens?.[stakedTokenAddr]?.decimals
  );

  const poolTvlUsd = Number(poolTvlFormatted || 0) * (prices?.[stakedTokenAddr] || 0);

  return (
    <PoolCardContainer>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <Tooltip placement="top" title={'DODO'}>
          <Box
            component="img"
            src={erc20Info?.[v2Vaults?.[vaultId]?.stakedTokenAddr as Address]?.iconUrl}
            sx={{ width: 56 }}
          />
        </Tooltip>
        <IconArrowRight sx={{ mx: 1, height: 15 }} />
        <Tooltip placement="top" title={v2Vaults?.[vaultId]?.tokenSymbol}>
          <Box component="img" src={v2Vaults?.[vaultId]?.iconUrl} sx={{ width: 56 }} />
        </Tooltip>
      </Stack>
      <Stack>
        <Typography variant="h2">{tokens?.[stakedTokenAddr]?.symbol} Pool</Typography>
        <Typography sx={{ '&&&': { pt: 0.5 } }}>
          Stake DODO, Earn {v2Vaults?.[vaultId]?.tokenSymbol}
        </Typography>
      </Stack>

      <Stack>
        <Tooltip placement="right" title={`$ ${commify(poolTvlUsd?.toFixed(2))}`}>
          <Typography component="span" variant="h2" width="max-content">
            {commify(poolTvlFormatted)} {tokens?.[stakedTokenAddr]?.symbol}
          </Typography>
        </Tooltip>
        <Typography>POOL TVL</Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <StakeERC20VaultButton onStaked={refetchPoolTvl} />
      </Stack>
    </PoolCardContainer>
  );
};

const CyberPoolCard = () => {
  // const { prices } = useStakedTokens();
  const stakedTokenSymbol = 'CYBER';

  const rewardTokens = [
    { tokenSymbol: stakedTokenSymbol, iconUrl: '/icons/iconCyber.svg' },
    altTokenInfo,
  ];
  // const CYBER_TOKEN_ADDRESS = '0x14778860e937f509e651192a90589de711fb88a9';

  // const { data: cyberTvl } = useQuery({
  //   queryKey: ['FETCH_CYBER_TVL'],
  //   queryFn: async () => {
  //     const response = await fetch('https://api.cyberconnect.dev/l2-public/stakeSummary');
  //     const result = await response.json();
  //     const cyberTvl = result?.data?.total_staked_cyber;

  //     return Number(formatWithPrecision(cyberTvl, undefined, 18));
  //   },
  // });

  // const usdTvl = (prices?.[CYBER_TOKEN_ADDRESS] || 0) * (cyberTvl || 0);

  return (
    <PoolCardContainer>
      <Stack alignItems="center" direction="row" justifyContent="flex-start">
        <Tooltip placement="top" title={'CYBER'}>
          <Box component="img" src={'/icons/iconCyber.svg'} sx={{ width: 56 }} />
        </Tooltip>
        <IconArrowRight sx={{ mx: 1, height: 15 }} />
        {rewardTokens?.map((tkn, index) => (
          <Tooltip key={tkn?.tokenSymbol} placement="top" title={tkn.tokenSymbol}>
            <Box
              component="img"
              src={tkn?.iconUrl}
              sx={{ width: 56, ml: -index * 2, zIndex: 1 + index }}
            />
          </Tooltip>
        ))}
      </Stack>
      <Stack>
        <Typography variant="h2">{stakedTokenSymbol} Pool</Typography>
        <Typography sx={{ '&&&': { pt: 0.5 } }}>
          STAKE {stakedTokenSymbol}, EARN {rewardTokens?.map(cur => cur.tokenSymbol)?.join(' + ')}
        </Typography>
      </Stack>
      {/* <Stack>
        <Tooltip placement="right" title={`$ ${commify(usdTvl?.toFixed(0))}`}>
          <Typography component="span" variant="h2" width="max-content">
            {commify(formatWithPrecision(cyberTvl || 0n, undefined, 18))} CYBER
          </Typography>
        </Tooltip>
        <Typography>POOL TVL</Typography>
      </Stack> */}
      <Stack>
        <Typography component="span" variant="h2" width="max-content">
          &nbsp;
        </Typography>
        <Typography>&nbsp;</Typography>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Button
          component={MuiLink}
          fullWidth
          href={'https://cyber.co/stake'}
          rel="noopener noreferrer"
          target="_blank"
          variant="contained"
        >
          Stake <IconExtLink sx={{ top: 0 }} />
        </Button>
      </Stack>
    </PoolCardContainer>
  );
};
