import { SvgIcon, SvgIconProps } from '@mui/material';

export default function IconGeoblock({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon fill="none" sx={{ color: '#202723', ...sx }} viewBox="0 0 80 80" {...props}>
      <g id="Group 40469">
        <path
          clipRule="evenodd"
          d="M64.1484 15.5322L77.6906 1.98995L76.7007 1L63.1585 14.5422L49.6164 1.00015L48.6265 1.9901L62.1685 15.5322L48.6265 29.0742L49.6164 30.0642L63.1585 16.5221L76.7007 30.0643L77.6906 29.0744L64.1484 15.5322ZM18.6464 11.1012C24.6879 6.73688 32.1088 4.16518 40.1323 4.16518V2.76518C31.6834 2.76518 23.8744 5.51378 17.5535 10.1654C8.12221 17.106 2 28.2868 2 40.8975C2 61.9574 19.0724 79.0298 40.1323 79.0298C61.1922 79.0298 78.2646 61.9574 78.2646 40.8975H77.2204L67.064 35.3577L66.9073 35.2722H66.7288H52.4454H51.8716L51.759 35.8349L49.2964 48.1482L49.2395 48.4325L49.4003 48.6737L54.3256 56.0617L54.4758 56.2869L54.7383 56.3525L64.0587 58.6826V68.7693C57.6311 74.2921 49.2714 77.6298 40.1323 77.6298C37.8835 77.6298 35.6818 77.4277 33.5446 77.0407L38.3473 58.3099L38.5135 57.662L37.8738 57.4666L20.3405 52.1092L13.0464 43.4474L18.2215 39.1348H24.9234L27.1926 43.6732L27.3861 44.0601H27.8187H30.2813H30.9813V43.3601V38.5375L38.3398 14.0094L38.6101 13.1083H37.6693H32.744H32.3114L32.1179 13.4952L29.8487 18.0336H23.268L18.6464 11.1012ZM65.4587 67.5028C72.1736 61.1086 76.4709 52.1985 76.8389 42.2841L66.5503 36.6722H53.0193L50.7261 48.1384L55.3404 55.0599L64.9285 57.4569L65.4587 57.5895V58.136V67.5028ZM32.1701 76.7645L36.8251 58.61L19.7336 53.3876L19.5359 53.3272L19.4028 53.1691L11.5223 43.811L11.069 43.2728L11.6096 42.8224L17.5199 37.8971L17.7146 37.7348H17.9681H25.356H25.7887L25.9821 38.1218L28.2513 42.6601H29.5813V38.4348V38.3321L29.6109 38.2337L36.7285 14.5083H33.1766L30.9074 19.0466L30.714 19.4336H30.2813H22.8934H22.5187L22.3109 19.1219L17.5255 11.9438C8.92588 18.6679 3.4 29.1373 3.4 40.8975C3.4 41.4863 3.41386 42.0719 3.44126 42.654L16.0348 57.185L16.4417 57.6545L16.0237 58.1143L11.1533 63.4718C16.3146 70.088 23.6921 74.8909 32.1701 76.7645ZM10.2983 62.331C6.7108 57.3463 4.34174 51.426 3.62722 45.0062L14.5699 57.6323L10.2983 62.331Z"
          fill="#202723"
          fillRule="evenodd"
          id="Union"
        />
      </g>
    </SvgIcon>
  );
}
