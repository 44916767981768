import { paths } from 'App';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useNavigate } from 'react-router-dom';

import NotificationBanner from './NotificationBanner';

export default function Notifications() {
  const { setConvertReAltDialogOpen } = useStakedTokens();
  const navigate = useNavigate();

  return (
    <>
      <NotificationBanner
        handleClick={() => setConvertReAltDialogOpen(true)}
        localStorageKey="notification.autocompound.dismissed"
        message="Save gas fees with auto-compound. Convert your stALT to reALT now!"
      />
      <NotificationBanner
        handleClick={() => navigate(paths.EigenRewards)}
        localStorageKey="notification.eigen.dismissed"
        message="EIGEN Drop claim is live! Check if you are eligible now!"
        sx={{
          '&& .MuiPaper-root': {
            background: 'linear-gradient(103deg, #C393FF 0%, #A281FF 100%)',
          },
        }}
      />
    </>
  );
}
