import { ReadContractErrorType } from '@wagmi/core';
import { useAlerts } from 'contexts/AlertsContext';
import { useWagmi } from 'contexts/WagmiContext';
import { useEffect, useState } from 'react';
import { useReadContract, UseReadContractParameters } from 'wagmi';

interface UseContractReadArgs<ReturnType> extends UseReadContractParameters {
  enabled?: boolean;
  onError?: (error: ReadContractErrorType) => void;
  select?: ((data: unknown) => ReturnType) | undefined;
  chainId?: number;
}

export function useContractRead<ReturnType>({
  abi,
  address,
  args,
  chainId,
  enabled,
  functionName,
  onError,
  select,
}: UseContractReadArgs<ReturnType>) {
  const { config } = useWagmi();
  const [errorHandled, setIsErrorHandled] = useState(false);

  const { data, error, ...others } = useReadContract({
    chainId,
    abi,
    address,
    args,
    functionName,
    query: {
      select,
      enabled,
      retry: false,
    },
    config,
  });

  const { showReadContractError } = useAlerts();

  useEffect(() => {
    if (error && !errorHandled) {
      setIsErrorHandled(true);

      if (onError) {
        onError?.(error);
      } else {
        showReadContractError(`"${functionName}"`, error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, errorHandled]);

  return { data, error, ...others };
}
