import { LoadingButton } from '@mui/lab';
import { ButtonProps, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import STAKE_ABI from 'core/contracts/STAKE_ABI';
import { commify, formatWithPrecision } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { useEffect, useState } from 'react';
import { VaultConfig } from 'types';
import { Address, formatUnits } from 'viem';
import { useAccount } from 'wagmi';

export default function RedeemButton({
  cooldownAmount,
  currentTimeSeconds,
  onConfirmed,
  producerNode,
  redeemStartTimeSeconds,
  stakingContractAddr,
  vaultConfig,
  ...rest
}: {
  producerNode: string;
  vaultConfig: VaultConfig;
  onConfirmed: () => void;
  cooldownAmount: bigint;
  redeemStartTimeSeconds: number;
  currentTimeSeconds: number;
  stakingContractAddr: Address;
} & ButtonProps) {
  const { address } = useAccount();

  const isWrongNetwork = useIsWrongNetwork();

  const { amountBN, amountRaw, setAmountRaw } = useAmount(18);

  const [showRedeemDialog, setShowRedeemDialog] = useState(false);

  const { stakingInfo } = useStakingInfo();
  const tokenSymbol = stakingInfo?.[vaultConfig?.id]?.[stakingContractAddr]?.stakedTokenSymbol;

  const { isPending: isLoading, write: redeem } = useWriteTx({
    contractAbi: STAKE_ABI,
    contractAddress: stakingContractAddr,
    functionName: 'unstake',
    functionArgs: [address, producerNode, amountBN],
    onTxConfirmed: () => {
      onConfirmed();
      setShowRedeemDialog(false);
    },
    txKey: `unstake_${stakingContractAddr}_${producerNode}`,
  });

  useEffect(() => {
    setAmountRaw(formatUnits(cooldownAmount, 18));
  }, [cooldownAmount, setAmountRaw]);

  return (
    <>
      <ConfirmationDialog
        confirmButtonProps={{
          disabled: amountBN <= 0 || amountBN > cooldownAmount,
        }}
        confirmButtonText={'Redeem'}
        handleClose={() => {
          setShowRedeemDialog(false);
        }}
        isLoading={isLoading}
        onConfirm={redeem}
        open={showRedeemDialog}
        title="Redeem amount"
      >
        <MaximizableAmount
          fullWidth
          setAmountRaw={setAmountRaw}
          tokenSymbol={tokenSymbol}
          value={amountRaw}
        />
        <Stack direction="row" justifyContent="space-between" mt={1.5}>
          <Typography variant="subtitle1">
            REDEEMABLE:&nbsp;
            <Typography component="span" variant="subtitle1">
              {commify(formatWithPrecision(cooldownAmount)) || '-'}
            </Typography>
            <Typography component="span" variant="subtitle1">
              &nbsp;{tokenSymbol}
            </Typography>
          </Typography>
          <MaxButton
            onClick={() => {
              setAmountRaw(formatUnits(cooldownAmount || 0n, 18));
            }}
          />
        </Stack>
      </ConfirmationDialog>
      <Stack alignItems="center" flexDirection="row" justifyContent="flex-end">
        <LoadingButton
          disabled={currentTimeSeconds < redeemStartTimeSeconds || isWrongNetwork}
          loading={isLoading}
          onClick={() => {
            setShowRedeemDialog(true);
          }}
          variant="contained"
          {...rest}
        >
          Redeem
        </LoadingButton>
      </Stack>
    </>
  );
}
