import '@rainbow-me/rainbowkit/styles.css';
import 'onLoad';
import 'polyfills';

import { ThemeProvider } from '@mui/material';
import App from 'App';
import { AlertsProvider } from 'contexts/AlertsContext';
import { StakedTokensContextProvider } from 'contexts/StakedTokensContext';
import { StakingInfoContextProvider } from 'contexts/StakingInfoContext';
import { TxStatusContextProvider } from 'contexts/TxStatusContext';
import { V2VaultsContextProvider } from 'contexts/V2VaultsContext';
import { WagmiProvider } from 'contexts/WagmiContext';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { theme } from 'theme';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <AlertsProvider>
          <BrowserRouter>
            <WagmiProvider>
              <StakingInfoContextProvider>
                <StakedTokensContextProvider>
                  <V2VaultsContextProvider>
                    <TxStatusContextProvider>
                      <App />
                    </TxStatusContextProvider>
                  </V2VaultsContextProvider>
                </StakedTokensContextProvider>
              </StakingInfoContextProvider>
            </WagmiProvider>
          </BrowserRouter>
        </AlertsProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}
