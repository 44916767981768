import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { commify, formatWithPrecision } from 'core/utils';
import useAirdrops from 'hooks/useAirdrops';
import { Fragment } from 'react';

import ClaimCells from './ClaimCells';

export function ClaimsTable() {
  const { airdropsInfo: tokenRows, isLoading } = useAirdrops();

  return (
    <>
      <Table sx={{ display: { xs: 'none', sm: 'table' } }}>
        <TableHead>
          <TableRow>
            <TableCell>Token</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={99}>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="center"
                  sx={{ fontWeight: 900 }}
                >
                  {isLoading ? <CircularProgress /> : 'You do not have any claimable rewards'}
                </Stack>
              </TableCell>
            </TableRow>
          ) : (
            <>
              {tokenRows?.map(row => {
                return (
                  <Fragment key={row?.id}>
                    <TableRow
                      sx={{
                        borderBottom: '1px solid #2027230F',
                      }}
                    />
                    <TableRow>
                      <TableCell>
                        <Stack alignItems="center" direction="row" gap={1}>
                          <Box
                            component="img"
                            src={row?.config?.iconUrl}
                            sx={{
                              width: 24,
                              marginRight: 0.5,
                            }}
                          />
                          {row?.config?.tokenName}
                        </Stack>
                      </TableCell>
                      <TableCell>
                        {!row?.amount
                          ? 'Not Eligible'
                          : commify(formatWithPrecision(BigInt(row?.amount || 0n)))}
                      </TableCell>
                      <ClaimCells tokenClaimData={row} />
                    </TableRow>
                  </Fragment>
                );
              })}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
}
