import { LoadingButton } from '@mui/lab';
import { Box, ButtonProps, FormControl, Stack, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import MaxButton from 'components/MaxButton';
import MaximizableAmount from 'components/MaximizableAmount';
import { useAlerts } from 'contexts/AlertsContext';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import { commify } from 'core/utils';
import { useAmount } from 'hooks/useAmount';
import { useContractRead } from 'hooks/useContractRead';
import { useIsWrongNetwork } from 'hooks/useIsWrongNetwork';
import { useWriteTx } from 'hooks/useWriteTx';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { erc20Abi, formatUnits } from 'viem';
import { useAccount } from 'wagmi';

const v2Vaults = window.appConfig?.v2Vaults;
const erc20Info = window.appConfig?.erc20Info;

export default function StakeERC20VaultButton({
  onStaked,
  sx,
  ...rest
}: ButtonProps & { onStaked?: () => void }) {
  const { vaultId: _vaultId } = useParams();
  const vaultId = parseInt(_vaultId || '');
  const stakedTokenAddr = v2Vaults?.[vaultId]?.stakedTokenAddr || '0x';
  const erc20VaultAddr = v2Vaults?.[vaultId]?.erc20VaultAddr || '0x';
  const { fetchTokenBalance, tokens } = useStakedTokens();
  const { balance, decimals, formattedBalance, symbol } = tokens?.[stakedTokenAddr] || {};
  const { formattedBalance: stakedFormattedBalance } = tokens?.[erc20VaultAddr] || {};
  const {
    amountBN: stakeAmtBN,
    amountRaw: stakeAmtRaw,
    setAmountRaw: setStakeAmtRaw,
  } = useAmount(decimals || 18);
  const { address } = useAccount();
  const isWrongNetwork = useIsWrongNetwork();
  const [showCooldownDialog, setShowCooldownDialog] = useState(false);

  const { showReadContractError } = useAlerts();
  const { data: allowance, refetch: refetchAllowance } = useContractRead<bigint>({
    address: stakedTokenAddr,
    abi: erc20Abi,
    functionName: 'allowance',
    args: address && erc20VaultAddr ? [address, erc20VaultAddr] : undefined,
    enabled: Boolean(address && erc20VaultAddr !== '0x' && stakedTokenAddr !== '0x'),
    onError: (err: Error) => {
      showReadContractError('allowance', err);
    },
  });

  const { isPending: isApproving, write: approve } = useWriteTx({
    contractAbi: erc20Abi,
    contractAddress: stakedTokenAddr,
    functionName: 'approve',
    functionArgs: [erc20VaultAddr, stakeAmtBN],
    txKey: `approve_${stakedTokenAddr}`,
    onTxConfirmed: () => {
      if (address) {
        refetchAllowance();
        fetchTokenBalance(stakedTokenAddr);
      }
    },
  });

  const hasAllowance = (allowance || 0n) >= (stakeAmtBN || 0n);

  const { isPending: isDepositing, write: deposit } = useWriteTx({
    contractAbi: ERC20_VAULT_ABI,
    contractAddress: erc20VaultAddr,
    functionName: 'deposit',
    functionArgs: [stakeAmtBN, address],
    txKey: `deposit_${stakedTokenAddr}`,
    onTxConfirmed: () => {
      if (address) {
        fetchTokenBalance(stakedTokenAddr);
        fetchTokenBalance(erc20VaultAddr);
        onStaked?.();
      }
    },
  });

  return (
    <>
      <ConfirmationDialog
        handleClose={() => {
          setShowCooldownDialog(false);
        }}
        open={showCooldownDialog}
        renderButtons={() => <></>}
        title={`Stake ${symbol}`}
      >
        <MaximizableAmount
          endAdornment={
            <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
              <Typography
                sx={{
                  fontSize: { xs: 14, sm: 18 },
                  color: theme => theme.colors.schema.primary,
                  textAlign: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  columnGap: 0.75,
                }}
              >
                <Box
                  component="img"
                  src={erc20Info?.[stakedTokenAddr]?.iconUrl}
                  sx={{ width: 30, ml: -1 }}
                />
                {symbol}
              </Typography>
            </FormControl>
          }
          fullWidth
          setAmountRaw={setStakeAmtRaw}
          tokenSymbol={symbol}
          value={stakeAmtRaw}
        />
        <Stack direction="row" justifyContent="space-between" mt={2}>
          <Typography variant="subtitle1">
            BALANCE:&nbsp;
            <Typography component="span" variant="subtitle1">
              {commify(formattedBalance) || '-'}
            </Typography>
            <Typography component="span" variant="subtitle1">
              &nbsp;{symbol}
            </Typography>
          </Typography>
          <MaxButton
            onClick={() => {
              const stringVal = formatUnits(balance, decimals);

              setStakeAmtRaw(stringVal);
            }}
          />
        </Stack>
        <Typography mt={{ md: 1.25, xs: 1 }} variant="subtitle1">
          STAKED BALANCE:&nbsp;
          <Typography component="span" variant="subtitle1">
            {commify(stakedFormattedBalance) || '-'}
          </Typography>
          <Typography component="span" variant="subtitle1">
            {/* Use erc20 symbol instead of vault symbol as exchange rate is 1:1 */}
            &nbsp;{symbol}
          </Typography>
        </Typography>
        <LoadingButton
          disabled={!stakeAmtBN || balance < stakeAmtBN}
          fullWidth
          loading={isApproving || isDepositing}
          onClick={() => {
            if (hasAllowance) {
              deposit();
            } else {
              approve();
            }
          }}
          sx={{ mt: 5 }}
          variant="contained"
        >
          {hasAllowance ? 'Stake' : 'Approve'}
        </LoadingButton>
      </ConfirmationDialog>
      <LoadingButton
        disabled={isWrongNetwork}
        fullWidth
        loading={isApproving || isDepositing}
        onClick={() => {
          setShowCooldownDialog(true);
        }}
        sx={sx}
        variant="contained"
        {...rest}
      >
        Stake
      </LoadingButton>
    </>
  );
}
