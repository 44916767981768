import { Box, BoxProps } from '@mui/material';
import { getTimeLeft } from 'core/utils';
import useAirdrops from 'hooks/useAirdrops';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { TokenClaimData } from '../../../api/_types/kv';

export type CurrentPeriod = 'Prior' | 'Active' | 'Ended' | '';

/**
 *
 * @prop renderChildren -- renders based on countdown timeframe
 */
export default function CountdownTimer({
  renderChildren,
  tokenClaimData,
  ...props
}: {
  tokenClaimData: TokenClaimData;
  renderChildren: (currentPeriod: CurrentPeriod) => ReactNode;
} & BoxProps) {
  const claimWindow = tokenClaimData?.config?.claimWindow;
  const { hasClaimed } = useAirdrops({ airdropKey: tokenClaimData?.id });

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(Math.round(Date.now() / 1000));

  useEffect(() => {
    if (window !== undefined) {
      const timer = setInterval(() => {
        setCurrentTimeSeconds(Math.round(Date.now() / 1000));
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  const currentPeriod = useMemo(() => {
    if (!claimWindow) {
      return '';
    }

    if (claimWindow?.start > currentTimeSeconds) {
      return 'Prior';
    }

    if (claimWindow?.end < currentTimeSeconds) {
      return 'Ended';
    }

    if (claimWindow?.start <= currentTimeSeconds && claimWindow?.end > currentTimeSeconds) {
      return 'Active';
    }

    return '';
  }, [claimWindow, currentTimeSeconds]);

  return (
    <>
      <Box sx={{ width: '25rem' }} {...props}>
        {hasClaimed
          ? 'Claimed'
          : currentPeriod === 'Prior'
          ? `Starts in: ${getTimeLeft(new Date(1000 * (claimWindow?.start || 0)))}`
          : currentPeriod === 'Active'
          ? `Ends in: ${getTimeLeft(new Date(1000 * (claimWindow?.end || 0)))}`
          : 'Ended'}
      </Box>
      {renderChildren?.(currentPeriod)}
    </>
  );
}
