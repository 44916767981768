import { Box, Typography } from '@mui/material';
import { MarkdownRenderer } from 'components/MarkdownRenderer';

import EigenTermsMd from './EigenAirdropTermsMd';

export default function EigenTerms() {
  return (
    <Box
      sx={{
        position: 'relative',
        overflow: 'scroll',
        background: 'linear-gradient(127deg, #EAF4DC 0%, #D5E9BB 100%)',
        maxHeight: '60vh',
        overscrollBehavior: 'none',
      }}
    >
      <MarkdownRenderer
        components={{
          h1: ({ children }) => (
            <Typography
              sx={{
                top: 0,
                textDecoration: 'underline',
                p: 2,
                pt: 6,
              }}
              variant="h1"
            >
              {children}
            </Typography>
          ),
        }}
        markdown={EigenTermsMd}
      />
    </Box>
  );
}
