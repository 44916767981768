import './terms.css';

import { Box } from '@mui/material';

export default function ClaimsTerms() {
  return (
    <Box
      id="terms-container"
      sx={{ position: 'relative', background: 'linear-gradient(127deg, #EAF4DC 0%, #D5E9BB 100%)' }}
    >
      <Box
        sx={{
          position: 'sticky',
          top: '-1rem',
          mb: 2,
          borderBottom: 'gray 1px solid',
          pb: 2,
          pt: 4,
          bgcolor: '#EAF4DC',
          px: 0,
        }}
      >
        <h2>
          <strong>Token Airdrop&nbsp;</strong>
        </h2>
        <h3>
          <strong>Terms and Conditions</strong>
        </h3>
      </Box>
      <p>
        <br />
        <br />
      </p>
      <p>
        <span>
          BY PARTICIPATING IN THE AIRDROP, PARTICIPANT ACKNOWLEDGES THAT THEY HAVE READ, UNDERSTOOD,
          AND AGREED TO THESE AIRDROP T&amp;CS AND THE TOKEN T&amp;CS IN THEIR ENTIRETY. THE
          PARTICIPANT IS RESPONSIBLE FOR MAKING ITS OWN DECISION IN RESPECT OF ITS PARTICIPATION IN
          THE AIRDROP AND ANY RECEIPT OF TOKENS. ANY PARTICIPATION IN THE AIRDROP IS SOLELY AT THE
          PARTICIPANT&rsquo;S OWN RISK AND IT IS THE PARTICIPANT&rsquo;S SOLE RESPONSIBILITY TO SEEK
          APPROPRIATE PROFESSIONAL, LEGAL AND TAX ADVICE IN RESPECT OF THE AIRDROP AND ANY RECEIPT
          OF THE TOKENS PRIOR TO PARTICIPATING IN THE AIRDROP AND PRIOR TO RECEIVING ANY TOKENS.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
          BY PARTICIPATING IN THE AIRDROP, THE PARTICIPANT EXPRESSLY ACKNOWLEDGES AND ASSUMES ALL
          RISKS RELATED THERETO INCLUDING (WITHOUT LIMITATION) THE RISKS SET OUT IN THE TOKEN
          T&amp;CS. IN NO EVENT SHALL THE ORGANISER OR ANY MEMBER OF THE ORGANISER GROUP BE HELD
          LIABLE IN CONNECTION WITH OR FOR ANY CLAIMS, LOSSES, DAMAGES OR OTHER LIABILITIES, WHETHER
          IN CONTRACT, TORT OR OTHERWISE, ARISING OUT OF OR IN CONNECTION WITH THE AIRDROP OR THE
          RECEIPT OF ANY TOKENS.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
          THE ORGANISER DOES NOT TAKE ANY RESPONSIBILITY FOR THE PARTICIPATION BY ANY PARTICIPANT IN
          THE AIRDROP AND SO EACH PARTICIPANT MUST SATISFY ITSELF BEFORE PARTICIPATING IN THE
          AIRDROP AND SHOULD NOT RELY ON THE ORGANISER. THE ORGANISER DOES NOT PROVIDE ANY
          RECOMMENDATION OR ADVICE IN RESPECT OF THE AIRDROP OR THE TOKENS. THE ORGANISER HAS NOT
          ASSESSED THE SUITABILITY OF THE TOKENS FOR ANY PARTICIPANT. THE ORGANISER HAS NOT
          CONSIDERED THE APPLICABLE LAWS, REGULATIONS AND LAWS OF THE JURISDICTION OF ANY
          PARTICIPANT. EACH PARTICIPANT PARTICIPATES IN THE AIRDROP AT ITS OWN RISK AND RECEIVES
          TOKENS AT ITS OWN RISK.
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>
        <span>
          These Token Airdrop Terms and Conditions (&quot;Airdrop T&amp;Cs&quot;) govern the
          participation in and receipt of tokens (&quot;Tokens&quot;) through the airdrop program
          (&quot;Airdrop&quot;) organised by Alt Research Ltd. (&quot;Organiser&quot;). By
          participating in the Airdrop, you (&quot;Participant&quot;) agree to be bound by these
          Airdrop T&amp;Cs. This T&C goes along with the User Interface disclaimer available here:{' '}
          <a
            className="text-blue-600"
            href="https://files.altlayer.io/airdrop_claim_ui_disclaimer.pdf"
            rel="noopener noreferrer"
          >
            https://files.altlayer.io/airdrop_claim_ui_disclaimer.pdf
          </a>
        </span>
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Eligibility:</strong>
            <br />
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>Participants must comply with all applicable laws and regulations.</span>
        </li>
        <li>
          <span>
            Participants must provide accurate and complete information as required for
            participation in the Airdrop.
          </span>
        </li>
        <li>
          <span>
            The Organiser reserves the right to verify the Participant&apos;s identity and
            eligibility.
          </span>
        </li>
        <li>
          <span>
            The Organiser at its sole discretion will determine the eligibility criteria for
            participation in the Airdrop, including the amount of Tokens to be distributed to
            eligible Participants that satisfy specified criteria. Different eligible Participants
            may receive different amounts of Tokens in any particular Airdrop depending on the
            criteria determined by the Organiser for such Airdrop. The Organiser will have no
            obligation to notify potential Airdrop participants of the eligibility criteria for any
            Airdrop prior to the execution of such Airdrop.
          </span>
        </li>
        <li>
          <span>
            Organiser reserves the sole and absolute right to disqualify any proposed Participant it
            deems ineligible for an Airdrop (be it under these Airdrop T&amp;Cs, the Token T&amp;Cs
            (as defined below) or by having engaged in any conduct that the Organiser considers
            inappropriate or unacceptable).
          </span>
        </li>
        <li>
          <span>Participants shall not:</span>
        </li>
        <ol>
          <li>
            <span>use multi-accounts and/or multi-wallets;</span>
          </li>
          <li>
            <span>attempt to cheat or hack the Airdrop or the Tokens;</span>
          </li>
          <li>
            <span>carry out any kind of spamming; or</span>
          </li>
          <li>
            <span>
              behave in a manner which the Organiser deems offensive or inappropriate, and any such
              behaviour will result in all of the Participant&rsquo;s accounts and/or wallets being
              permanently banned from all of the Organiser&rsquo;s Airdrops.
            </span>
          </li>
        </ol>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Airdrop Details:</strong>
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            Airdrop Period: The Airdrop will be conducted during the specified period, as determined
            by the Organiser (the &ldquo;Airdrop Period&rdquo;).
          </span>
        </li>
        <li>
          <span>
            Participation Requirements: Participant must follow the specified instructions set out
            in the Schedule hereto and/or such other instructions as are provided by the Organiser
            from time to time to participate in the Airdrop.
          </span>
        </li>
        <li>
          <span>
            &nbsp;Token Allocation: The number of Tokens allocated to each Participant will be
            determined solely by the Organiser, and such allocation may vary among Participants.
          </span>
        </li>
        <li>
          <span>
            &nbsp;No Purchase Required: Participation in the Airdrop does not require any purchase
            or payment although there may be transaction fees payable by the Participant to receive
            and/or claim their Tokens.
          </span>
          <span>
            <br />
            <br />
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Token Distribution:</strong>
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            Token Distribution: The distribution of Tokens will occur after the Airdrop Period ends,
            subject to successful verification and compliance with these Airdrop T&amp;Cs.
          </span>
        </li>
        <li>
          <span>
            Wallet Requirement: Participants must provide a compatible wallet address capable of
            receiving the Tokens. Failure to provide a valid wallet address may result in the
            forfeiture of Tokens.
          </span>
        </li>
        <li>
          <span>
            Token Transfer: Tokens will either be transferred to the designated wallet addresses
            provided by Participants within a reasonable timeframe or be claimable by such
            designated wallet, subject to technical limitations or delays. There may be transaction
            fees payable by the Participant to receive and/or claim such Tokens.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Use of Tokens:</strong>
            <br />
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            &nbsp;Token Usage: Participants acknowledge and agree that the Tokens received through
            the Airdrop may have specific usage limitations, such as utility within a specific
            platform or ecosystem, and may have no monetary value.
          </span>
        </li>
        <li>
          <span>
            No Investment Advice: Tokens received through the Airdrop are not intended as investment
            advice, and each Participant acknowledges that their value (if any) may fluctuate and
            that no guarantees are made regarding future value (if any).
          </span>
          <span>
            <br />
            <br />
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Participant Representations and Warranties:</strong>
            <strong>
              <br />
              <br />
            </strong>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <span>
            Compliance: Participant represents and warrants that they will comply with all
            applicable laws and regulations in relation to the Airdrop and the use of Tokens.
          </span>
        </li>
        <li>
          <span>
            Accuracy of Information: Participant represents and warrants that all information
            provided during the Airdrop registration process is true, accurate, and complete.
          </span>
        </li>
        <li>
          <span>
            Prohibited Person: Participant represents and warrants that they are not a Prohibited
            Person as that term is defined in the Token T&amp;Cs.
          </span>
        </li>
        <li>
          <span>
            Participant&rsquo;s Technical Skills: Participant represents and warrants that it has at
            least a basic degree of understanding of the operation, functionality, usage, storage,
            transmission mechanisms and other material characteristics of cryptocurrencies,
            blockchain-based software systems, cryptocurrency wallets or other related token storage
            mechanisms, blockchain technology and smart contract technology.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Disclaimers:</strong>
            <strong>
              <br />
              <br />
            </strong>
          </strong>
        </li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <span>
            To the maximum extent permitted by all applicable laws, regulations and rules and except
            as otherwise provided in these Airdrop T&amp;Cs, the Organiser Group (as defined below)
            hereby expressly disclaims its liability and shall in no case be liable to a Participant
            or any other person for:
          </span>
        </li>
        <ol>
          <li>
            <span>postponement, suspension and/or abortion of the Airdrop;</span>
          </li>
          <li>
            <span>
              &nbsp;any failure, or disruption to, the Airdrop, the operations of the Organiser or
              any other technology (including but not limited to smart contract technology)
              associated therewith, due to any reason, including but not limited to occurrences of
              hacks, mining attacks (including but not limited to double-spend attacks, majority
              mining power attacks and &quot;selfish-mining&quot; attacks), cyber-attacks,
              distributed denials of service, errors, vulnerabilities, defects, flaws in programming
              or source code or otherwise, regardless of when such failure, malfunction, breakdown,
              or disruption occurs;
            </span>
          </li>
          <li>
            <span>
              &nbsp;any failure, malfunction, virus, error, bug, flaw, defect in the technology
              connected with or use for affecting the Tokens or a Participant&rsquo;s participation
              in the Airdrop;
            </span>
          </li>
          <li>
            <span>
              &nbsp;any prohibition, restriction or regulation by any government or regulatory
              authority in any jurisdiction applicable to the Tokens or a Participant&rsquo;s
              participation in the Airdrop;
            </span>
          </li>
          <li>
            <span>
              &nbsp;loss of possession of the credentials for accessing, or loss or destruction of
              the private keys of, any wallet and/or the Participant&rsquo;s designated wallet
              addresses, in any manner and to any extent;
            </span>
          </li>
          <li>
            <span>
              &nbsp;failure to disclose information relating to the progress of the Airdrop;
            </span>
          </li>
          <li>
            <span>
              &nbsp;any risks associated with a Participant&rsquo;s participation in the Airdrop
              and/or a Participant&rsquo;s use of the Tokens; and
            </span>
          </li>
          <li>
            <span>
              &nbsp;all other risks, direct, indirect or ancillary, whether in relation to a
              Participant&rsquo;s participation in the Airdrop and/or a Participant&rsquo;s use of
              the Tokens which are not specifically or explicitly contained in or stated in these
              Airdrop T&amp;Cs.
            </span>
          </li>
        </ol>
        <li>
          <span>
            For the purposes of these Airdrop T&amp;Cs &quot;Organiser Group&rdquo; means the
            Organiser and each of its Affiliates. &quot;Affiliate&quot; with respect to any person,
            means any other person directly or indirectly controlling, controlled by or under common
            control with such person; and with respect to the Organiser, means any other person
            directly or indirectly controlling, controlled by or under common control with such
            person.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Limitation of Liability and Indemnification:</strong>
            <br />
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            &nbsp;To the maximum extent permitted by the applicable laws, regulations and rules:
          </span>
        </li>
        <ol>
          <li>
            <span>
              &nbsp;the Organiser Group and the Indemnified Persons (as defined below) shall not be
              liable for any indirect, special, incidental, consequential or other losses of any
              kind, in tort, contract or otherwise (including but not limited to loss of revenue,
              income or profits, and loss of use or data), arising out of or in connection with a
              Participant&rsquo;s participation in the Airdrop;
            </span>
          </li>
          <li>
            <span>
              &nbsp;without prejudice to the foregoing, each Participant hereby agree that the
              aggregate liability of the members of the Organiser Group, in tort, contract or
              otherwise, arising out of or in connection with the participation in the Airdrop shall
              in any event be limited to US$1.00; and
            </span>
          </li>
          <li>
            <span>
              &nbsp;each Participant hereby agrees to waive all rights to assert any claims against
              the Organiser Group and/or the Indemnified Persons under such applicable laws,
              regulations and rules.
            </span>
          </li>
        </ol>
        <li>
          <span>
            &nbsp;To the maximum extent permitted by the applicable laws, regulations and rules,
            each Participant shall indemnify, defend, and hold the Organiser Group and the
            Indemnified Persons harmless from and against any and all claims, damages, losses,
            suits, actions, demands, proceedings, expenses, and/or liabilities (including but not
            limited to reasonable legal fees incurred and/or those necessary to successfully
            establish the right to indemnification) filed/incurred by any third party against any
            member of the Organiser Group or the Indemnified Persons arising out of a breach by the
            Participant of any warranty, representation, or obligation hereunder.
          </span>
        </li>
        <li>
          <span>
            &nbsp;For the purposes of these Airdrop T&amp;Cs &quot;Indemnified Persons&quot; means
            the Organiser Group and each of their respective subsidiaries, related companies,
            Affiliates, directors, officers, employees, agents, service providers, successors, and
            permitted assignees.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>Governing Law and Jurisdiction:</strong>
            <br />
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            Governing Law: This Airdrop T&amp;Cs shall be governed by and construed in accordance
            with the laws of the British Virgin Islands.
          </span>
        </li>
        <li>
          <span>
            Jurisdiction: Any disputes arising from or in connection with this Airdrop T&amp;Cs
            shall be subject to the exclusive jurisdiction of the courts of the British Virgin
            Islands.
          </span>
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <strong>
            <strong>General Terms:</strong>
            <br />
          </strong>
        </li>
      </ul>
      <ol>
        <li>
          <span>
            Intellectual Property: Participant acknowledges and agrees that all intellectual
            property rights related to the Airdrop and the Tokens, including but not limited to
            trademarks, logos, and content, are the sole property of the Organiser.
          </span>
        </li>
        <li>
          <span>
            Modification and Termination: The Organiser reserves the right to modify, suspend, or
            terminate the Airdrop or these Airdrop T&amp;Cs at any time without prior notice.
          </span>
        </li>
        <li>
          <span>
            The Tokens are not intended to constitute securities of any form, units in a business
            trust, units in a collective investment scheme or any other form of investment in any
            jurisdiction. Neither these Airdrop T&amp;Cs nor the Token T&amp;Cs constitute a
            prospectus or offer document of any sort and are not intended to constitute an offer of
            securities of any form, units in a business trust, units in a collective investment
            scheme or any other form of investment, or a solicitation for any form of investment in
            any jurisdiction. No regulatory authority has examined or approved of these Airdrop
            T&amp;Cs. No such action has been or will be taken by the Organiser under the laws,
            regulatory requirements or rules of any jurisdiction.
          </span>
        </li>
      </ol>
      <p>
        <br />
        <br />
      </p>
      <p className="text-center text-lg font-bold">
        Certain Risks Relating to Purchase, Sale and Use of ALT
      </p>
      <br />
      <p>
        <strong>
          IMPORTANT NOTE: AS NOTED ELSEWHERE IN THIS AGREEMENT, ALT IS NOT BEING STRUCTURED OR SOLD
          AS SECURITIES OR ANY OTHER FORM OF INVESTMENT PRODUCT. ACCORDINGLY, NONE OF THE
          INFORMATION PRESENTED IN THIS SCHEDULE 3 IS INTENDED TO FORM THE BASIS FOR ANY INVESTMENT
          DECISION, AND NO SPECIFIC RECOMMENDATIONS ARE MADE OR INTENDED. THE SELLER EXPRESSLY
          DISCLAIMS ANY AND ALL RESPONSIBILITY FOR ANY DIRECT OR CONSEQUENTIAL LOSS OR DAMAGE OF ANY
          KIND WHATSOEVER ARISING DIRECTLY OR INDIRECTLY FROM: (I) RELIANCE ON ANY INFORMATION
          CONTAINED IN THIS SCHEDULE 3, (II) ANY ERROR, OMISSION OR INACCURACY IN ANY SUCH
          INFORMATION (III) ANY ACTION RESULTING FROM SUCH INFORMATION OR (IV) THE SALE OR USE OF
          ALT.
        </strong>
      </p>
      <br />

      <p>
        <span>
          By purchasing, holding and using ALT, the Buyer expressly acknowledges that it understands
          and assumes a variety of risks (including without limitation the following risks):
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of changes in functionality</strong>
      </p>
      <p>
        <span>
          ALT does not have any rights, uses, purpose, attributes, functionalities or features,
          express or implied, except for those which are specifically described in the Token
          Documentation and which may change from time to time.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>ALT is non-refundable</strong>
      </p>
      <p>
        <span>
          The Seller is not obliged to provide ALT holders with a refund related to ALT for any
          reason, and ALT holders acknowledge and agree that they will not receive money or other
          compensation in lieu of a refund. No promises of future performance or price are or will
          be made in respect to ALT, including no promise of inherent value, no promise of
          continuing payments, and no guarantee that ALT will hold any particular value. Therefore,
          the recovery of spent resources may be impossible or may be subject to foreign laws or
          regulations, which may not be the same as the laws in the jurisdiction of ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Associated with the related Blockchain and underlying protocol</strong>
      </p>
      <p>
        <span>
          Because ALT and AltLayer are based on blockchain technology, any malfunction, breakdown or
          abandonment of the relevant blockchain may have a material adverse effect on AltLayer or
          ALT. Moreover, advances in cryptography, or technical advances such as the development of
          quantum computing, could present risks to ALT and AltLayer by rendering ineffective the
          cryptographic consensus mechanism that underpins the relevant blockchain. The future of
          cryptography and security innovations is highly unpredictable.
        </span>
      </p>
      <p>
        <span>
          Further, products or services available on AltLayer may require digital assets provided by
          a user to be deposited with, &quot;staked&quot; with and/or otherwise require interaction
          with various third party decentralised finance protocols which primarily comprise smart
          contracts deployed on the relevant blockchain. These third party decentralised finance
          protocols and the development team behind these protocols are independent third parties,
          so no Group Entity has control over these protocols or teams. These deployed smart
          contracts underlying the decentralised finance protocols may contain security
          vulnerabilities, errors, failures, bugs or economic loopholes which may be exploited by
          third parties, causing you to suffer losses in connection with any product(s) available on
          AltLayer. Neither the Seller nor any Group Entity can be responsible for any such security
          vulnerabilities, errors, failures, bugs or economic loopholes.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Losing Access to ALT Due to Loss of Private Key(s)</strong>
      </p>
      <p>
        <span>
          A private key, or a combination of private keys, is necessary to control and dispose of
          ALT stored in the Buyer&apos;s digital wallet, vault or other storage mechanism.
          Accordingly, loss of requisite private key(s) associated with the Buyer&apos;s digital
          wallet, vault or other storage
        </span>
        <span>
          <br />
        </span>
      </p>
      <p>
        <span>
          mechanism storing ALT may result in loss of such ALT. Moreover, any third party that gains
          access to such private key(s), including by gaining access to login credentials of a
          hosted wallet service used by the Buyer, may be able to misappropriate any ALT held by the
          Buyer. The Seller cannot be responsible for any such losses.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Blockchain Level Attacks</strong>
      </p>
      <p>
        <span>
          As with other decentralised cryptographic tokens based on blockchain technology, ALT is
          susceptible to attacks by nodes or validators responsible for validating/confirming ALT
          transactions on the relevant blockchain, including, but not limited, to double-spend
          attacks, majority mining power attacks, and selfish-mining attacks. Any successful attacks
          present a risk to AltLayer and ALT, including, but not limited to, accurate execution and
          recording of transactions involving ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Hacking and Security Weaknesses</strong>
      </p>
      <p>
        <span>
          Hackers or other malicious groups or organisations may attempt to interfere with AltLayer
          or ALT in a variety of ways, including, but not limited to, malware attacks, denial of
          service attacks, consensus-based attacks, Sybil attacks, smurfing and spoofing.
          Furthermore, because AltLayer is based on open-source software, there is a risk that a
          third party or a member of any Group Entity may intentionally or unintentionally introduce
          weaknesses into the core infrastructure of AltLayer, which could negatively affect
          AltLayer and ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Uninsured Losses</strong>
      </p>
      <p>
        <span>
          Unlike in the case of bank accounts or accounts at financial institutions, ALT is
          uninsured unless the buyer specifically obtains private insurance to insure them. Thus, in
          the event of loss or loss of utility value, there is no public insurer or private
          insurance arranged by any Group Entity to provide recourse (and in any event, the Seller
          is not obliged to compensate or insure the Buyer for any event of loss or loss of utility
          value).
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Associated with Uncertain Regulations and Enforcement Actions</strong>
      </p>
      <p>
        <span>
          The regulatory status of ALT and distributed ledger technology is unclear or unsettled in
          many jurisdictions, but numerous regulatory authorities across jurisdictions have been
          outspoken about considering the implementation of regulatory regimes which govern digital
          asset markets. It is difficult to predict how or whether regulatory agencies may apply
          existing regulation with respect to such technology and its applications, including
          AltLayer and ALT. It is likewise difficult to predict how or whether legislatures or
          regulatory agencies may implement changes to law and regulation affecting distributed
          ledger technology and its applications, including AltLayer and ALT. Regulatory actions
          could negatively impact AltLayer and ALT in various ways, including, for purposes of
          illustration only, through a determination that ALT is a regulated financial instrument
          that requires registration or licensing, AltLayer may not be able to provide regular
          service in certain areas. Any Group Entity may cease operations in a jurisdiction, or even
          abandon certain features of the project, in the event that regulatory actions, or changes
          to applicable law or regulation, make it illegal to operate in such jurisdiction, or
          commercially undesirable to obtain the necessary regulatory approval(s) to operate in such
          jurisdiction.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Arising from Taxation</strong>
      </p>
      <p>
        <span>
          The tax characterisation of ALT and this Agreement is uncertain. The Seller intends to
          treat ALT and this Agreement neither as an equity interest nor as a debt interest in the
          Seller for tax purposes. It is possible that the Seller&rsquo;s intended treatment of ALT
          and this Agreement may be challenged, so that the tax consequences to the Buyer and the
          Seller relating to ALT and this Agreement could differ from those described above. The
          Buyer must seek its own tax advice in connection with purchasing, holding and utilising
          ALT, which may result in adverse tax consequences to the Buyer, including, without
          limitation, withholding taxes, transfer taxes, value added taxes, income taxes and similar
          taxes, levies, duties or other charges and tax reporting requirements.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of alternative networks / competitors</strong>
      </p>
      <p>
        <span>
          It is possible that alternative networks could be established in an attempt to facilitate
          services that are similar to the Services, or alternative networks could be established
          that utilise the same or similar code and protocol underlying ALT and/or AltLayer.
          AltLayer may compete with these alternative networks, which could negatively impact
          AltLayer and/or ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Insufficient Interest in AltLayer or Distributed Applications</strong>
      </p>
      <p>
        <span>
          It is possible that AltLayer will not be used by a large number of individuals, companies
          and other entities or that there will be limited public interest in the creation and
          development of distributed ecosystems (such as that on AltLayer) more generally. Such lack
          of use or interest could negatively impact the development of AltLayer and therefore the
          potential utility of ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Associated with the Development and Maintenance of AltLayer</strong>
      </p>
      <p>
        <span>
          AltLayer is still under development and may undergo significant changes over time.
          Although it is intended for ALT and AltLayer to follow the specifications set forth in
          Schedule 1, and the relevant Group Entity will endeavour to work towards those ends
          (subject to internal business considerations), changes may be required to be made to the
          specifications of ALT or AltLayer for any number of reasons. This could create the risk
          that ALT or AltLayer, as further developed and maintained, may not meet the Buyer&apos;s
          expectations or requirements at the time of purchase. Furthermore, despite the
          Seller&apos;s good faith efforts to develop and maintain AltLayer, it is still possible
          that AltLayer will experience malfunctions or otherwise fail to be adequately developed or
          maintained, which may negatively impact AltLayer and ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Inadequate disclosure of information</strong>
      </p>
      <p>
        <span>
          As at the date hereof, AltLayer is still under development and its design concepts,
          consensus mechanisms, algorithms, codes, and other technical details and parameters may be
          constantly and frequently updated and changed. Although this Agreement contains the most
          current information relating to AltLayer, it is not absolutely complete and may still be
          adjusted and updated by the team from time to time. The team has no ability and obligation
          to keep holders of ALT informed of every detail (including development progress and
          expected milestones) regarding the project to develop AltLayer, hence insufficient
          information disclosure is inevitable and reasonable.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of an Unfavourable Fluctuation of Currency Value</strong>
      </p>
      <p>
        <span>
          The Seller intends to use the proceeds from selling ALT to support the maintenance and
          development of AltLayer, as described further in Schedule 2. The proceeds of the sale of
          ALT will be mainly denominated in USDT, USDC and possibly other digital assets, and may be
          converted into other cryptographic and fiat currencies. In addition, sales of ALT may also
          be denominated in fiat currencies. If the value of digital assets fluctuates unfavourably
          during or after the sale of ALT, the relevant Group Entity may not be able to support
          development plans, or may not be able to maintain AltLayer in the manner that it intended.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Related to Corporate Structure</strong>
      </p>
      <p>
        <span>
          The legal structure for the Group Entities (including the intra-Group arrangements) is
          bespoke and there is no generally accepted standard or structure for similar projects in
          the distributed ledger technology space. The legal structure had been designed to address
          certain specific legal risks, and attempt to decentralise the management and control, as
          well as economic risks and benefits relating to AltLayer and ALT; but there is no legal
          precedent for whether these structures are effective and it is difficult to predict the
          position that a regulator may adopt. The legal structure and contractual arrangements may
          not be effective in decentralising management and control, thereby adversely affecting the
          value of AltLayer and/or ALT.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risk of Dissolution of the Seller, any Group Entity or AltLayer</strong>
      </p>
      <p>
        <span>
          Start-up companies such as the Seller involve a high degree of risk. Financial and
          operating risks confronting start-up companies are significant, and the Seller is not
          immune to these. Start-up companies often experience unexpected problems in the areas of
          product development, marketing, financing, and general management, among others, which
          frequently cannot be solved.
        </span>
      </p>
      <p>
        <span>
          It is possible that, due to any number of reasons, including, but not limited to, an
          unfavourable fluctuation in the value of cryptographic and fiat currencies, decrease in
          the utility of ALT due to negative adoption of AltLayer, the failure of commercial
          relationships, or Intellectual Property ownership challenges, AltLayer may no longer be
          viable to operate and the Seller or any Group Entity may be dissolved.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Arising from Lack of Governance Rights</strong>
      </p>
      <p>
        <span>
          Because ALT confers no governance rights of any kind with respect to the Seller or any
          Group Entity, all decisions involving the Seller or its Affiliates will be made by the
          relevant Group Entity at its sole and absolute discretion, including, but not limited to,
          decisions to discontinue the operations or development of AltLayer, to create and sell
          more ALT or other digital assets for use within AltLayer, or to sell or liquidate any
          Group Entity. These decisions could adversely affect AltLayer and ALT held by the Buyer.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks Involving Cloud Storage</strong>
      </p>
      <p>
        <span>
          As AltLayer may provide or utilise a decentralised cloud storage service for users and
          applications, therefore the Services are susceptible to a number of risks related to the
          storage of data in the cloud. The Services may involve the storage of large amounts of
          sensitive and/or proprietary information, which may be compromised in the event of a
          cyberattack or other malicious activity. Similarly, the Services may be interrupted and
          files may become temporarily unavailable in the event of such an attack or malicious
          activity. Because users can utilise a variety of hardware and software that may interface
          with AltLayer, there is the risk that the Services may become unavailable or interrupted
          based on a failure of interoperability or an inability to integrate these third-party
          systems and devices that the Group Entities do not control. The risk that the Services may
          face increasing interruptions and AltLayer may face additional security vulnerabilities
          could adversely affect AltLayer and therefore the future utility of any ALT held by the
          Buyer.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Forking</strong>
      </p>
      <p>
        <span>
          AltLayer is intended to be a community project and certain elements are open-sourced. The
          Seller (nor any Group Entity) does not and cannot monopolise the development, marketing,
          operation or otherwise of AltLayer and underlying blockchain. Any entity may independently
          develop a patch or upgrade of the source code of AltLayer and underlying blockchain (or
          any part thereof) without prior authorisation of any other Person, and launch the same by
          deployment on any underlying blockchain network. The acceptance of these patches or
          upgrades by a sufficient (not necessarily overwhelming) percentage of ALT holders could
          result in a &quot;fork&quot; in the network, and consequently two diverging networks will
          emerge and remain. Each branch arising from the fork will have its own native
          cryptographic tokens &ndash; accordingly there will be two different versions of AltLayer
          and/or ALT respectively residing in the two divergent branches with almost identical
          technical features and functions. The tokens on the diverging network will not be ALT.
        </span>
      </p>
      <p>
        <span>
          Further, it is theoretically possible for each branch of the forked blockchain to be
          further forked an unlimited number of times. The temporary or permanent existence of
          forked blockchains could adversely affect the operation of AltLayer and underlying
          blockchain and the ALT held by the Buyer, and may ruin the sustainability of AltLayer.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Risks associated with a lack of markets for ALT</strong>
      </p>
      <p>
        <span>
          There is no prior market for ALT and the sale of ALT may not result in an active or liquid
          market for ALT. ALT is designed to be utilised solely within AltLayer, hence there may be
          illiquidity risk with respect to ALT held by the Buyer. ALT is not a currency issued by
          any central bank or national, supra-national or quasi-national organisation, nor is it
          backed by any hard assets or other credit nor is it a &quot;commodity&quot; in the usual
          and traditional sense of that word. The Seller is not responsible for, nor does the Seller
          pursue, the circulation and trading of ALT on any market. Trading of ALT will merely
          depend on the consensus on its value between the relevant market participants. No one is
          obliged to purchase any ALT from any holder of ALT, nor does anyone guarantee the
          liquidity or market price of ALT to any extent at any time. Furthermore, ALT may not be
          resold to a purchaser who is a citizen, national, resident (tax or otherwise), domiciliary
          or green card holder of a Restricted Territory or to purchasers where the purchase of ALT
          may be in violation of applicable laws. Accordingly, the Seller cannot ensure that there
          will be any demand or market for ALT, or that the Purchase Price paid for ALT is
          indicative of any market valuation or market price for ALT.
        </span>
      </p>
      <p>
        <span>
          Even if secondary trading of ALT is facilitated by third party exchanges, such exchanges
          may be relatively new and subject to little or no regulatory oversight, making them more
          susceptible to fraud or manipulation. Furthermore, to the extent that third parties do
          ascribe an external exchange value to ALT (e.g., as denominated in a digital or fiat
          currency), such value may be extremely volatile, decline below the Purchase Price which
          the Buyer had paid for ALT, and/or diminish to zero.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Inflation</strong>
      </p>
      <p>
        <span>
          Due to the nature and operation of blockchain protocols, the total amount of circulating
          ALT could increase through the adoption of a new patch or upgrade of the source code. Any
          inflation in the quantity of ALT could adversely affect the market price of ALT, and
          consequently buyers may suffer economic losses. Neither the Seller nor any Group Entity
          provides any guarantee that a buyer would be compensated (in any manner) for such
          inflation.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Loss of Talent</strong>
      </p>
      <p>
        <span>
          The development of AltLayer depends on the continued co-operation of the existing
          technical team and expert consultants. The loss of any member may adversely affect
          AltLayer or its future development. Further, stability and cohesion within the team is
          critical to the overall development of AltLayer. There is the possibility that conflict
          within the team and/or departure of core personnel may occur, resulting in negative
          influence on the project in the future.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Failure to develop</strong>
      </p>
      <p>
        <span>
          AltLayer is still in the developmental stage, hence there may be large changes to the
          final design before the official version is released. There is the risk that the
          development of AltLayer will not be executed or implemented as planned, or may not meet
          any expectation of purchasers of ALT, for a variety of reasons, including without
          limitation the event of a decline in the prices of any digital asset, virtual currency or
          ALT, unforeseen technical difficulties, and shortage of development funds for activities.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>Unanticipated Risks</strong>
      </p>
      <p>
        <span>
          Cryptographic tokens such as ALT are a new and untested technology. In addition to the
          aforementioned risks, there may be other risks associated with the Buyer&apos;s purchase,
          holding and use of ALT, including those that the Seller cannot anticipate. Such risks may
          further materialise as unanticipated variations or combinations of the risks discussed in
          this Schedule 3.&nbsp;&nbsp;
        </span>
      </p>
      <p>
        <br />
        <br />
      </p>
    </Box>
  );
}
