import { Box, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import ActionMenu from 'components/ActionMenu';
import IconTimer from 'components/icons/IconTimer';
import { useStakedTokens } from 'contexts/StakedTokensContext';
import { useStakingInfo } from 'contexts/StakingInfoContext';
import ERC20_VAULT_ABI from 'core/contracts/ERC20_VAULT_ABI';
import { commify, formatWithPrecision, getTimeLeft, SECONDS_IN_YEAR } from 'core/utils';
import { NodeBalance } from 'hooks/useComputedStakingValues';
import { useContractRead } from 'hooks/useContractRead';
import { useEffect, useState } from 'react';
import { V2VaultConfig, VaultConfig } from 'types';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

import CooldownButton from './CooldownButton';
import RedeemButton from './RedeemButton';
import UnstakeERC20VaultButton from './UnstakeERC20VaultButton';

export enum UnstakeWindowState {
  NOT_STARTED = 'NOT_STARTED',
  COOLING_DOWN = 'COOLING_DOWN',
  REDEEMABLE = 'REDEEMABLE',
}

const launchPoolTokens = window.appConfig?.launchPoolTokens;
const reAltAddr = window.appConfig?.erc4626Vault;
const erc20Info = window.appConfig?.erc20Info;

export function VaultsTableRow({
  balance,
  contractIndex,
  operatorAddr,
  stakingContractAddr,
  vaultConfig,
}: {
  stakingContractAddr: Address;
  operatorAddr: Address;
  vaultConfig: VaultConfig;
  balance: NodeBalance;
  contractIndex: number;
}) {
  const [unstakeWindowState, setUnstakeWindowState] = useState(UnstakeWindowState.NOT_STARTED);
  const { refetchNodeCooldownInfo, refetchNodeStakedBalance, stakingInfo } = useStakingInfo();
  const { prices } = useStakedTokens();
  const nodeBalance = balance;
  const cooldownSeconds = stakingInfo?.[vaultConfig?.id]?.[stakingContractAddr]?.cooldownSeconds;
  const { amount: cooldownAmount, timestamp: cooldownTimestamp } = stakingInfo?.[vaultConfig?.id]?.[
    stakingContractAddr
  ]?.cooldowns?.[operatorAddr] || { timestamp: 0, amount: 0n };
  const { distributionMap, globalVotingStake, operatorList, stakedTokenAddr, stakedTokenSymbol } =
    stakingInfo?.[vaultConfig?.id]?.[stakingContractAddr] || {};
  const operatorInfo = operatorList?.find(cur => cur.address === operatorAddr);

  // on UI, wait for 1 additional block to avoid gas estimation issues due to last block timestamp being used instead of current time
  const avgBlockTimeSeconds = Number(window?.appConfig?.avgBlockTimeSeconds);
  const redeemStartTimeSeconds = (cooldownSeconds || 0) + cooldownTimestamp + avgBlockTimeSeconds;

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(Math.round(Date.now() / 1000));

  useEffect(() => {
    if (window !== undefined) {
      const timer = setInterval(() => {
        const currentSeconds = Math.round(Date.now() / 1000);

        if (currentSeconds === redeemStartTimeSeconds) {
          refetchNodeCooldownInfo(vaultConfig?.id, stakingContractAddr, operatorAddr);
        }

        setCurrentTimeSeconds(currentSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    operatorAddr,
    redeemStartTimeSeconds,
    refetchNodeCooldownInfo,
    vaultConfig?.id,
    stakingContractAddr,
  ]);

  const sumEmissionsPerYearUsd = Object.values(distributionMap || {}).reduce((sum, dist) => {
    const distEpy = dist?.eps * SECONDS_IN_YEAR;
    const distEpyUsd = Number(formatWithPrecision(distEpy)) * (prices?.[dist.rewardToken] || 0);

    return sum + distEpyUsd;
  }, 0);

  const globalVotingStakeUsd =
    Number(formatWithPrecision(globalVotingStake || 0n)) *
    (prices?.[stakedTokenAddr as Address] || 0);

  const operatorAprBps =
    globalVotingStakeUsd > 0
      ? (sumEmissionsPerYearUsd * (10000 - (operatorInfo?.feeBps || 0))) / globalVotingStakeUsd
      : 0;

  useEffect(() => {
    setUnstakeWindowState(
      cooldownTimestamp === 0 || !cooldownTimestamp
        ? UnstakeWindowState.NOT_STARTED
        : currentTimeSeconds < redeemStartTimeSeconds
        ? UnstakeWindowState.COOLING_DOWN
        : UnstakeWindowState.REDEEMABLE
    );
  }, [cooldownTimestamp, currentTimeSeconds, redeemStartTimeSeconds]);

  const isLaunchPool = Boolean(launchPoolTokens?.[vaultConfig?.id]);

  return (
    <>
      {nodeBalance?.accruingRewards !== 0n && (
        <TableRow
          sx={{
            borderBottom: '1px solid #2027230F',
          }}
        >
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <Box component="img" src={vaultConfig?.iconUrl} sx={{ width: 24 }} />
              {contractIndex === 0 ? vaultConfig?.name : ''}
            </Stack>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {isLaunchPool ? (
              <Typography>LaunchPool</Typography>
            ) : (
              `${commify((operatorAprBps / 100).toFixed(1))}%`
            )}
          </TableCell>
          <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <Box component="img" src={vaultConfig?.iconUrl} sx={{ width: 24 }} />
              {vaultConfig?.name}
              <br />
              {isLaunchPool ? (
                <Typography>[LaunchPool]</Typography>
              ) : (
                `[${commify((operatorAprBps / 100).toFixed(1))}%]`
              )}
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="center" direction="row">
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(formatWithPrecision(nodeBalance?.accruingRewards || 0n)) *
                    (prices?.[stakedTokenAddr as Address] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Stack alignItems="center" direction="row">
                  <Box
                    component="img"
                    src={erc20Info?.[stakedTokenAddr as Address]?.iconUrl}
                    sx={{
                      width: 24,
                      mr: 1,
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  >
                    {commify(formatWithPrecision(nodeBalance?.accruingRewards || 0n))}{' '}
                    {stakedTokenSymbol}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Typography sx={{ display: { xs: 'inline', md: 'none' } }}>(Staked)</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Staked</TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <ActionMenu
              renderPopoverContent={closePopover => (
                <CooldownButton
                  onConfirmed={closePopover}
                  operatorAddr={operatorAddr}
                  stakingContractAddr={stakingContractAddr}
                  sx={{ width: '100%', display: { xs: 'block', md: 'none' } }}
                  vaultConfig={vaultConfig}
                />
              )}
              sx={{ display: { xs: 'block', md: 'none' } }}
            />
            <Stack
              columnGap={2}
              direction="row"
              justifyContent="flex-end"
              sx={{ display: { xs: 'none', md: 'flex' } }}
            >
              <CooldownButton
                operatorAddr={operatorAddr}
                stakingContractAddr={stakingContractAddr}
                vaultConfig={vaultConfig}
              />
            </Stack>
          </TableCell>
        </TableRow>
      )}
      {(nodeBalance?.withdrawable || nodeBalance?.inCooldown) && (
        <TableRow>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {contractIndex === 0 && nodeBalance?.accruingRewards === 0n && (
              <Stack alignItems="center" direction="row" gap={1}>
                <Box
                  component="img"
                  src={vaultConfig?.iconUrl}
                  sx={{
                    width: 24,
                  }}
                />
                {vaultConfig?.name}
              </Stack>
            )}
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {contractIndex === 0 &&
              nodeBalance?.accruingRewards === 0n &&
              `${commify((operatorAprBps / 100).toFixed(1))}%`}
          </TableCell>
          <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
            {contractIndex === 0 && nodeBalance?.accruingRewards === 0n && (
              <Stack alignItems="center" direction="row" gap={1}>
                <Box
                  component="img"
                  src={vaultConfig?.iconUrl}
                  sx={{
                    width: 24,
                  }}
                />
                {vaultConfig?.name}
                <br />({commify((operatorAprBps / 100).toFixed(1))}%)
              </Stack>
            )}
          </TableCell>
          <TableCell>
            <Stack alignItems="center" direction="row">
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(
                      formatWithPrecision(
                        nodeBalance?.withdrawable || nodeBalance?.inCooldown || 0n
                      )
                    ) * (prices?.[stakedTokenAddr as Address] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Stack alignItems="center" direction="row">
                  <Box
                    component="img"
                    src={erc20Info?.[stakedTokenAddr as Address]?.iconUrl}
                    sx={{
                      width: 24,
                      mr: 1,
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  >
                    {commify(
                      formatWithPrecision(
                        nodeBalance?.withdrawable || nodeBalance?.inCooldown || 0n
                      )
                    )}{' '}
                    {stakedTokenSymbol}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Typography sx={{ display: { xs: 'inline', md: 'none' } }}>
              (
              {unstakeWindowState === UnstakeWindowState.COOLING_DOWN
                ? 'Pending cooldown'
                : 'Unstaked'}
              )
            </Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {unstakeWindowState === UnstakeWindowState.COOLING_DOWN
              ? 'Pending cooldown'
              : 'Unstaked'}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {unstakeWindowState === UnstakeWindowState.COOLING_DOWN ? (
              <Typography sx={{ fontWeight: 600, fontStyle: 'italic' }}>
                <IconTimer sx={{ width: 12, height: 12, display: { xs: 'inline', md: 'none' } }} />
                {getTimeLeft(new Date(redeemStartTimeSeconds * 1000))}{' '}
                <Typography sx={{ fontWeight: 600, display: { xs: 'none', md: 'inline' } }}>
                  remaining...
                </Typography>
              </Typography>
            ) : (
              <>
                <ActionMenu
                  renderPopoverContent={closePopover => (
                    <RedeemButton
                      cooldownAmount={cooldownAmount}
                      currentTimeSeconds={currentTimeSeconds}
                      onConfirmed={() => {
                        closePopover();
                        refetchNodeCooldownInfo(vaultConfig?.id, stakingContractAddr, operatorAddr);
                        refetchNodeStakedBalance(
                          vaultConfig?.id,
                          stakingContractAddr,
                          operatorAddr
                        );
                      }}
                      producerNode={operatorAddr}
                      redeemStartTimeSeconds={redeemStartTimeSeconds}
                      stakingContractAddr={stakingContractAddr}
                      sx={{ display: { xs: 'block', md: 'none' } }}
                      vaultConfig={vaultConfig}
                    />
                  )}
                  sx={{ display: { xs: 'block', md: 'none' } }}
                />
                <RedeemButton
                  cooldownAmount={cooldownAmount}
                  currentTimeSeconds={currentTimeSeconds}
                  onConfirmed={() => {
                    refetchNodeCooldownInfo(vaultConfig?.id, stakingContractAddr, operatorAddr);
                    refetchNodeStakedBalance(vaultConfig?.id, stakingContractAddr, operatorAddr);
                  }}
                  producerNode={operatorAddr}
                  redeemStartTimeSeconds={redeemStartTimeSeconds}
                  stakingContractAddr={stakingContractAddr}
                  sx={{ display: { xs: 'none', md: 'flex' } }}
                  vaultConfig={vaultConfig}
                />
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export function VaultsV2TableRow({ vaultConfig }: { vaultConfig: V2VaultConfig }) {
  const { prices, tokens } = useStakedTokens();
  const reAltBalance = tokens?.[reAltAddr]?.balance;
  const isLaunchPool = !vaultConfig?.isLive;

  return (
    <>
      {
        <TableRow
          sx={{
            borderBottom: '1px solid #2027230F',
          }}
        >
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <Box
                component="img"
                src={vaultConfig?.iconUrl}
                sx={{
                  width: 24,
                  marginRight: 0.5,
                }}
              />
              {vaultConfig?.name}
            </Stack>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            <Typography>{isLaunchPool ? 'LaunchPool' : 'TBD'}</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
            <Stack alignItems="center" direction="row">
              <Box
                component="img"
                src={vaultConfig?.iconUrl}
                sx={{
                  width: 24,
                  marginRight: 0.5,
                }}
              />
              {vaultConfig?.name}
              {/* Assume all v2 vaults are launchPools for now */}
              &nbsp;<Typography>[{isLaunchPool ? 'LaunchPool' : 'TBD'}]</Typography>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="center" direction="row">
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(formatWithPrecision(reAltBalance || 0n)) * (prices?.[reAltAddr] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Stack alignItems="center" direction="row">
                  <Box
                    component="img"
                    src={'/icons/iconReAlt.svg'}
                    sx={{
                      width: 24,
                      mr: 1,
                      borderRadius: '50%',
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  >
                    {commify(formatWithPrecision(reAltBalance || 0n))} reALT
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Typography sx={{ display: { xs: 'inline', md: 'none' } }}>(Delegated)</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Delegated</TableCell>
        </TableRow>
      }
    </>
  );
}

export function Erc20VaultsTableRow({ vaultConfig }: { vaultConfig: V2VaultConfig }) {
  const erc20Addr = vaultConfig?.stakedTokenAddr || '0x';
  const vaultAddr = vaultConfig?.erc20VaultAddr || '0x';
  const { prices, tokens } = useStakedTokens();
  const { address } = useAccount();

  const erc20Token = tokens?.[erc20Addr] || {};
  const stakedToken = tokens?.[vaultAddr] || {};

  const { data: cooldownSeconds } = useContractRead<number>({
    address: vaultAddr,
    abi: ERC20_VAULT_ABI,
    functionName: 'cooldownSeconds',
    onError: () => {},
  });

  const { data: cooldownInfo, refetch: refetchCooldownInfo } = useContractRead<[bigint, number]>({
    address: vaultAddr,
    abi: ERC20_VAULT_ABI,
    functionName: 'cooldowns',
    args: [address],
    enabled: Boolean(address),
    onError: () => {},
  });
  const [cooldownAmount, cooldownTimestamp] = cooldownInfo || [];
  // on UI, wait for 1 additional block to avoid gas estimation issues due to last block timestamp being used instead of current time
  const avgBlockTimeSeconds = Number(window?.appConfig?.avgBlockTimeSeconds);
  const redeemStartTimeSeconds =
    avgBlockTimeSeconds + ((cooldownSeconds as number) || 0) + ((cooldownTimestamp as number) || 0);

  const [currentTimeSeconds, setCurrentTimeSeconds] = useState(Math.round(Date.now() / 1000));

  useEffect(() => {
    if (window !== undefined) {
      const timer = setInterval(() => {
        const currentSeconds = Math.round(Date.now() / 1000);

        if (currentSeconds === redeemStartTimeSeconds) {
          refetchCooldownInfo();
        }

        setCurrentTimeSeconds(currentSeconds);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [redeemStartTimeSeconds, refetchCooldownInfo]);

  return (
    <>
      {stakedToken?.balance && (
        <TableRow
          sx={{
            borderBottom: '1px solid #2027230F',
          }}
        >
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            <Stack alignItems="center" direction="row" gap={1}>
              <Box
                component="img"
                src={vaultConfig?.iconUrl}
                sx={{
                  width: 24,
                  marginRight: 0.5,
                }}
              />
              {vaultConfig?.name}
            </Stack>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {/* Assume all v2 vaults are launchPools for now */}
            <Typography>LaunchPool</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
            <Stack alignItems="center" direction="row">
              <Box
                component="img"
                src={vaultConfig?.iconUrl}
                sx={{
                  width: 24,
                  marginRight: 0.5,
                }}
              />
              {vaultConfig?.name}
              {/* Assume all v2 vaults are launchPools for now */}
              &nbsp;<Typography>[LaunchPool]</Typography>
            </Stack>
          </TableCell>
          <TableCell>
            <Stack alignItems="center" direction="row">
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(formatWithPrecision(stakedToken?.balance || 0n)) *
                    (prices?.[erc20Addr] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Stack alignItems="center" direction="row">
                  <Box
                    component="img"
                    src={erc20Info?.[erc20Addr]?.iconUrl}
                    sx={{
                      width: 24,
                      mr: 1,
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  >
                    {/* Show stakeToken balance with erc20Token symbol as exchange rate is 1:1 */}
                    {commify(formatWithPrecision(stakedToken?.balance || 0n))} {erc20Token?.symbol}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Typography sx={{ display: { xs: 'inline', md: 'none' } }}>(Staked)</Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>Staked</TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              justifyContent: 'flex-end',
              display: { xs: 'table-cell', md: 'none' },
            }}
          >
            <ActionMenu
              renderPopoverContent={() => (
                <UnstakeERC20VaultButton
                  cooldownInfo={cooldownInfo}
                  cooldownSeconds={cooldownSeconds}
                  onConfirmed={() => refetchCooldownInfo()}
                  vaultConfig={vaultConfig}
                />
              )}
              sx={{ display: { xs: 'inline-flex', md: 'none' } }}
            />
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              justifyContent: 'flex-end',
              display: { xs: 'none', md: 'table-cell' },
            }}
          >
            <UnstakeERC20VaultButton
              cooldownInfo={cooldownInfo}
              cooldownSeconds={cooldownSeconds}
              onConfirmed={() => refetchCooldownInfo()}
              vaultConfig={vaultConfig}
            />
          </TableCell>
        </TableRow>
      )}
      {cooldownAmount && (
        <TableRow
          sx={{
            borderBottom: '1px solid #2027230F',
          }}
        >
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {!stakedToken?.balance && (
              <Stack alignItems="center" direction="row" gap={1}>
                <Box
                  component="img"
                  src={vaultConfig?.iconUrl}
                  sx={{
                    width: 24,
                    marginRight: 0.5,
                  }}
                />
                {vaultConfig?.name}
              </Stack>
            )}
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {/* Assume all v2 vaults are launchPools for now */}
            {!stakedToken?.balance && <Typography>LaunchPool</Typography>}
          </TableCell>
          <TableCell sx={{ display: { xs: 'table-cell', md: 'none' } }}>
            {!stakedToken?.balance && (
              <Stack alignItems="center" direction="row">
                <Box
                  component="img"
                  src={vaultConfig?.iconUrl}
                  sx={{
                    width: 24,
                    marginRight: 0.5,
                  }}
                />
                {vaultConfig?.name}
                {/* Assume all v2 vaults are launchPools for now */}
                &nbsp;<Typography>[LaunchPool]</Typography>
              </Stack>
            )}
          </TableCell>
          <TableCell>
            <Stack alignItems="center" direction="row">
              <Tooltip
                placement="right"
                title={`~$${commify(
                  (
                    Number(formatWithPrecision(cooldownAmount || 0n)) * (prices?.[erc20Addr] || 0)
                  )?.toFixed(2)
                )}`}
              >
                <Stack alignItems="center" direction="row">
                  <Box
                    component="img"
                    src={erc20Info?.[erc20Addr]?.iconUrl}
                    sx={{
                      width: 24,
                      mr: 1,
                    }}
                  />
                  <Typography
                    component="span"
                    sx={{ cursor: 'default', ':hover': { textDecoration: 'underline' } }}
                  >
                    {/* Show stakeToken balance with erc20Token symbol as exchange rate is 1:1 */}
                    {commify(formatWithPrecision(cooldownAmount || 0n))} {erc20Token?.symbol}
                  </Typography>
                </Stack>
              </Tooltip>
            </Stack>
            <Typography sx={{ display: { xs: 'inline', md: 'none' } }}>
              ({currentTimeSeconds < redeemStartTimeSeconds ? 'Pending cooldown' : 'Unstaked'})
            </Typography>
          </TableCell>
          <TableCell sx={{ display: { xs: 'none', md: 'table-cell' } }}>
            {currentTimeSeconds < redeemStartTimeSeconds ? 'Pending cooldown' : 'Unstaked'}
          </TableCell>
          <TableCell
            sx={{
              textAlign: 'right',
              justifyContent: 'flex-end',
            }}
          >
            {currentTimeSeconds < redeemStartTimeSeconds ? (
              <Typography sx={{ fontWeight: 600, fontStyle: 'italic' }}>
                <IconTimer sx={{ width: 12, height: 12, display: { xs: 'inline', md: 'none' } }} />
                {getTimeLeft(new Date(redeemStartTimeSeconds * 1000))}{' '}
                <Typography sx={{ fontWeight: 600, display: { xs: 'none', md: 'inline' } }}>
                  remaining...
                </Typography>
              </Typography>
            ) : (
              <>
                <ActionMenu
                  renderPopoverContent={() => (
                    <UnstakeERC20VaultButton
                      actionName="Redeem"
                      cooldownInfo={cooldownInfo}
                      onConfirmed={() => refetchCooldownInfo()}
                      vaultConfig={vaultConfig}
                    />
                  )}
                  sx={{ display: { xs: 'inline-flex', md: 'none' } }}
                />
                <UnstakeERC20VaultButton
                  actionName="Redeem"
                  cooldownInfo={cooldownInfo}
                  onConfirmed={() => refetchCooldownInfo()}
                  sx={{ display: { xs: 'none', md: 'inline-flex' } }}
                  vaultConfig={vaultConfig}
                />
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
