import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Stack,
  TableCell,
  Typography,
} from '@mui/material';
import ClaimsTerms from 'components/ClaimsTerms';
import IconGeoblock from 'components/icons/IconGeoblock';
import MaximizableAmount from 'components/MaximizableAmount';
import { commify, formatWithPrecision } from 'core/utils';
import useAirdrops from 'hooks/useAirdrops';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { TokenClaimData } from '../../../api/_types/kv';
import CountdownTimer, { CurrentPeriod } from './CountdownTimer';

const tokenIdsWithDedicatedPages = ['eigen'];

export default function ClaimCells({
  sx,
  tokenClaimData,
  ...props
}: { tokenClaimData: TokenClaimData } & ButtonProps) {
  const [showDialog, setShowDialog] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const navigate = useNavigate();

  const {
    claim,
    hasClaimed,
    isClaiming: isLoading,
    isLoading: isCheckingClaimed,
  } = useAirdrops({ airdropKey: tokenClaimData?.id });

  return (
    <>
      <Dialog onClose={() => setShowDialog(false)} open={showDialog}>
        <DialogTitle>
          <Typography fontWeight={500} variant="h2">
            Claim {tokenClaimData?.config?.tokenName}
          </Typography>
        </DialogTitle>

        <DialogContent>
          {tokenClaimData?.geoblocked ? (
            <Stack sx={{ maxWidth: '426px' }}>
              <IconGeoblock sx={{ width: 80, height: 80, mx: 'auto' }} />
              <Typography
                mt={3}
                sx={{ fontSize: 18, textAlign: 'center', maxWidth: '252px', mx: 'auto' }}
              >
                {tokenClaimData?.config?.tokenName} claim feature is not available in your region
              </Typography>
              <Typography
                mt={3}
                sx={{
                  fontSize: 11,
                  fontWeight: 500,
                  letterSpacing: '0.66px',
                  lineHeight: '16px',
                  background: 'linear-gradient(127deg, #EAF4DC 0%, #D5E9BB 100%)',
                  px: '12px',
                  py: '10px',
                  textTransform: 'uppercase',
                }}
              >
                Due to legal restrictions, we cannot accept users from your country.
              </Typography>
            </Stack>
          ) : !agreedToTerms ? (
            <>
              <Box sx={{ maxHeight: '60vh', overflow: 'scroll' }}>
                <ClaimsTerms />
              </Box>
              <Button
                fullWidth
                onClick={() => {
                  setAgreedToTerms(true);
                }}
                sx={{ mx: 'auto', mt: 3 }}
                variant="contained"
              >
                Agree
              </Button>
            </>
          ) : hasClaimed ? (
            <Typography>
              You have successfully claimed{' '}
              {formatWithPrecision(BigInt(tokenClaimData?.amount || 0))}{' '}
              {tokenClaimData?.config?.tokenName}
            </Typography>
          ) : isCheckingClaimed ? (
            <Stack alignItems="center" justifyContent="center" minWidth="17rem">
              <CircularProgress size={20} />
            </Stack>
          ) : (
            <CountdownTimer
              renderChildren={(currentPeriod: CurrentPeriod) =>
                currentPeriod !== 'Ended' && (
                  <>
                    <MaximizableAmount
                      disabled
                      endAdornment={
                        <FormControl sx={{ minWidth: { xs: 100, lg: 140 } }}>
                          <Typography
                            sx={{
                              fontSize: { xs: 14, sm: 18 },
                              color: theme => theme.colors.schema.primary,
                              textAlign: 'center',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              columnGap: 0.75,
                            }}
                          >
                            <Box
                              component="img"
                              src="/icons/iconReAlt.svg"
                              sx={{
                                width: 26,
                                bgcolor: 'white',
                                border: '1px solid rgb(237,235,254)',
                                borderRadius: '50%',
                              }}
                            />
                            {tokenClaimData?.config?.tokenName}
                          </Typography>
                        </FormControl>
                      }
                      fullWidth
                      setAmountRaw={() => null}
                      sx={{ mt: 1 }}
                      tokenSymbol={tokenClaimData?.config?.tokenName}
                      value={commify(formatWithPrecision(BigInt(tokenClaimData?.amount)))}
                    />
                    <Stack direction="row" justifyContent="space-between" mt={1.5}>
                      <Typography variant="subtitle1">
                        REDEEMABLE:&nbsp;
                        <Typography component="span" variant="subtitle1">
                          {commify(formatWithPrecision(BigInt(tokenClaimData?.amount))) || '-'}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                          &nbsp;{tokenClaimData?.config?.tokenName}
                        </Typography>
                      </Typography>
                    </Stack>
                    <LoadingButton
                      disabled={currentPeriod !== 'Active'}
                      fullWidth
                      loading={isLoading}
                      onClick={claim}
                      sx={{ mt: 3 }}
                      variant="contained"
                    >
                      Claim {tokenClaimData?.config?.tokenName}
                    </LoadingButton>
                  </>
                )
              }
              tokenClaimData={tokenClaimData}
            />
          )}
        </DialogContent>
      </Dialog>
      <CountdownTimer
        component={TableCell}
        renderChildren={(currentPeriod: CurrentPeriod) => (
          <TableCell align="right">
            <Button
              disabled={currentPeriod !== 'Active' || hasClaimed || !tokenClaimData?.amount}
              onClick={() => {
                if (tokenIdsWithDedicatedPages.includes(tokenClaimData?.id || '')) {
                  navigate('/rewards/' + tokenClaimData?.id);
                } else {
                  setShowDialog(true);
                }
              }}
              sx={{
                ...sx,
              }}
              variant="contained"
              {...props}
            >
              Claim
            </Button>
          </TableCell>
        )}
        tokenClaimData={tokenClaimData}
      />
    </>
  );
}
