import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_SEVERITY, useAlerts } from 'contexts/AlertsContext';
import AIRDROP_MANAGER_ABI from 'core/contracts/AIRDROP_MANAGER_ABI';
import { useCallback, useMemo } from 'react';
import { encodePacked, hashMessage, hexToSignature, keccak256 } from 'viem';
import { useAccount, useSignMessage } from 'wagmi';

import { ClaimData, ClaimDataRes, ClaimsConfig, TokenClaimData } from '../../api/_types/kv';
import { useContractRead } from './useContractRead';
import { useWriteTx } from './useWriteTx';

const airdropManagerAddr = window?.appConfig?.airdropManager;

interface UseAirdropProps {
  airdropKey?: string;
}

export default function useAirdrops(props?: UseAirdropProps) {
  const { airdropKey } = props || {};
  const { address } = useAccount();
  const queryClient = useQueryClient();
  const { data: claimsConfig, isLoading: isLoadingConfig } = useQuery({
    queryKey: ['FETCH_CLAIM_CONFIG'],
    queryFn: async () => {
      const res = await fetch('/api/config');

      return (await res.json()) as ClaimsConfig;
    },
  });

  const { data: claimsData, isLoading: isLoadingClaimAmounts } = useQuery({
    queryKey: ['FETCH_CLAIM_AMOUNTS', address],
    queryFn: async () => {
      const res = await fetch(`/api/claims/${address?.toLowerCase()}`); // prod data is lowercase, TODO: remove this temp fix

      return (await res.json()) as ClaimDataRes;
    },
    enabled: !!address,
  });

  const airdropsInfo: TokenClaimData[] = Object.entries(claimsConfig || {})?.map(([key, value]) => {
    const tokenClaimData = claimsData?.[key] || ({} as ClaimData);

    return {
      id: key,
      ...tokenClaimData,
      config: value,
    };
  });

  const tokenClaimData: TokenClaimData = useMemo(
    () => airdropsInfo?.find(cur => cur.id === airdropKey) || ({} as TokenClaimData),
    [airdropKey, airdropsInfo]
  );

  const msgToSign = tokenClaimData?.config?.msgToSign;

  const leaf = useMemo(() => {
    if (!address || !tokenClaimData?.amount) return '0x';

    const packed = encodePacked(
      ['address', 'uint256'],
      [address, BigInt(tokenClaimData?.amount || 0)]
    );

    return keccak256(packed);
  }, [tokenClaimData?.amount, address]);

  const {
    data: hasClaimed,
    isLoading: isCheckingClaimed,
    queryKey: checkClaimedQueryKey,
  } = useContractRead<boolean>({
    abi: AIRDROP_MANAGER_ABI,
    address: airdropManagerAddr,
    functionName: 'claimed',
    args: [BigInt(tokenClaimData?.config?.airdropId || 0), leaf],
    enabled: Boolean(address && tokenClaimData?.config?.airdropId && leaf && leaf !== '0x'),
  });

  const { addAlert } = useAlerts();

  const {
    data: signature,
    isPending: isSigning,
    reset: resetSignature,
    signMessage,
  } = useSignMessage({
    mutation: {
      onSuccess: () => {
        addAlert({
          title: 'Message signed',
          desc: 'You may now claim your tokens',
          severity: ALERT_SEVERITY.SUCCESS,
        });
      },
    },
  });

  const splitSig = useMemo(() => {
    if (!signature) return undefined;

    console.debug('raw msg to sign: ', msgToSign);

    const messageHash = hashMessage(msgToSign || '');

    console.debug(
      'Ensure that message hash stored on contract is the EIP191 message hash: ',
      messageHash
    );

    return hexToSignature(signature);
  }, [msgToSign, signature]);

  const { isPending: isClaiming, write: claim } = useWriteTx({
    contractAbi: AIRDROP_MANAGER_ABI,
    contractAddress: airdropManagerAddr,
    functionName: 'claim',
    functionArgs: [
      BigInt(tokenClaimData?.config?.airdropId || 0),
      BigInt(tokenClaimData?.amount || 0),
      tokenClaimData?.proof,
      true,
    ],
    txKey: `claim_${tokenClaimData?.config?.airdropId}_${address}`,
    onTxConfirmed: () => {
      queryClient.invalidateQueries({ queryKey: checkClaimedQueryKey });
    },
  });

  const { error: claimWSignatureError, write: claimWSignature } = useWriteTx({
    contractAbi: AIRDROP_MANAGER_ABI,
    contractAddress: airdropManagerAddr,
    functionName: 'claimWithSignature',
    functionArgs: [
      BigInt(tokenClaimData?.config?.airdropId || 0),
      BigInt(tokenClaimData?.amount || 0),
      tokenClaimData?.proof,
      Number(splitSig?.v),
      splitSig?.r,
      splitSig?.s,
    ],
    txKey: `claim_${tokenClaimData?.config?.airdropId}_${address}`,
    onTxConfirmed: () => {
      queryClient.invalidateQueries({ queryKey: checkClaimedQueryKey });
    },
  });

  const isLoading = isLoadingConfig || isLoadingClaimAmounts || isCheckingClaimed;

  const _signMessage = useCallback(
    () => signMessage({ account: address, message: msgToSign || '' }),
    [address, msgToSign, signMessage]
  );

  return useMemo(
    () => ({
      isLoading,
      airdropsInfo,
      tokenClaimData,
      claim,
      claimWSignature,
      hasClaimed,
      isClaiming,
      signMessage: _signMessage,
      isSigning,
      resetSignature,
      signature,
      claimWSignatureError,
    }),
    [
      _signMessage,
      airdropsInfo,
      claim,
      claimWSignature,
      hasClaimed,
      isClaiming,
      isLoading,
      isSigning,
      resetSignature,
      tokenClaimData,
      signature,
      claimWSignatureError,
    ]
  );
}
