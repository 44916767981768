import { Box, Grid, Snackbar, SnackbarProps, Typography } from '@mui/material';
import IconClose from 'components/icons/IconClose';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { PropsWithChildren, ReactNode, useState } from 'react';

interface NotificationBannerPropsBase
  extends PropsWithChildren<Omit<SnackbarProps, 'onClose' | 'message'>> {
  handleClose?: () => void;
  localStorageKey?: string;
  handleClick?: () => void;
}
type NotificationBannerProps =
  | (NotificationBannerPropsBase & {
      renderContent: (handleClose: () => void) => ReactNode;
      message?: never;
    })
  | (NotificationBannerPropsBase & { message: ReactNode; renderContent?: never });

export default function NotificationBanner({
  handleClick,
  handleClose: _handleClose,
  localStorageKey = 'notification_dismissed',
  message,
  renderContent,
  sx,
  ...props
}: NotificationBannerProps) {
  const [hasDismissed, setHasDismissed] = useLocalStorage(localStorageKey, false);
  const [isOpen, setIsOpen] = useState(!hasDismissed);

  const handleClose = () => {
    setHasDismissed(true);
    setIsOpen(false);
    _handleClose?.();
  };

  return (
    <Snackbar
      ContentProps={{ elevation: 0 }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      message={
        !message && renderContent ? (
          renderContent?.(handleClose)
        ) : (
          <Grid
            alignItems="center"
            container
            flexDirection={'row'}
            justifyContent="space-between"
            px={2}
            spacing={0.5}
          >
            <Grid container item justifyContent="center" lg={11.5} xs={11}>
              <Typography
                color="white"
                onClick={() => {
                  handleClick?.();
                  handleClose();
                }}
                sx={{ cursor: 'pointer', ':hover': { textDecoration: 'underline' } }}
              >
                {message}
              </Typography>
            </Grid>
            <Grid item lg={0.5} xs={1}>
              <Box
                onClick={handleClose}
                sx={{
                  right: '1rem',
                  cursor: 'pointer',
                  '& svg': { width: '1.4rem', height: '1.4rem' },
                }}
              >
                <IconClose />
              </Box>
            </Grid>
          </Grid>
        )
      }
      onClose={() => {}}
      open={isOpen}
      sx={{
        '&&': {
          top: { xs: 140, lg: 80 },
          left: { xs: 0, lg: 240, xl: 378 },
          mr: { xs: 4, sm: 0 },
          width: { xs: '100vw', lg: 'calc(100vw - 240px)', xl: 'calc(100vw - 378px)' },
          display: 'flex',
          justifyContent: 'center',
        },
        '& .MuiPaper-root': {
          width: '100vw',
          bgcolor: '#62AA55',
          display: 'block',
          borderRadius: 0,
        },
        ...sx,
      }}
      // autoHideDuration={5000}
      {...props}
    />
  );
}
